import React, { useContext, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import Pagination from "../components/Pagination.generic";
import Input from "../components/Input.generic";
import { getScanByCustomerIdFiltered } from "../services/Scan.service";
import { AuthContext } from "../contexts/AuthContext";
import {
  calculateAverageVirusCount,
  formatDate,
} from "../services/Utils.services";
import { checkForMultipleAntivirusDetection } from "../utils/func";

function Scans() {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [customerId, setCustomerId] = useState("");
  const [totalPage, setTotalPage] = useState(null);
  const [data, setData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const { user } = useContext(AuthContext);

  let PageSize = +10;

  useEffect(() => {
    if (user) {
      setCustomerId(user?.customers[0]?.id);
    }
  }, [user]);

  useEffect(() => {
    const delayQueryTimeoutId = setTimeout(() => {
      setDebouncedQuery(query.toLowerCase().trim());
    }, 1500);
    return () => clearTimeout(delayQueryTimeoutId);
  }, [query, 1500]);

  useEffect(() => {
    if (customerId) {
      if (debouncedQuery) {
        getScanByCustomerIdFiltered(
          customerId,
          currentPage - 1,
          10,
          debouncedQuery
        ).then((resp) => {
          setTotalPage(resp.data.max_page);
          if (resp.data.max_page <= currentPage - 1) {
            setCurrentPage(1);
          }
          setData(resp.data);
        });
      } else {
        getScanByCustomerIdFiltered(customerId, currentPage - 1, 10).then(
          (resp) => {
            setTotalPage(resp.data.max_page);
            setData(resp.data);
          }
        );
      }
    }
  }, [customerId, currentPage, debouncedQuery]);

  useEffect(() => {
    if (data.length !== 0) {
      let array = [];
      data.scans.forEach((scan) => {
        const existingEntry = array.find(
          (entry) => entry.id === scan.scan_identifier
        );

        if (existingEntry) {
          existingEntry.scan.push(scan);
        } else {
          array.push({ id: scan.scan_identifier, scan: [scan] });
        }
      });
      setFormattedData(array);
    }
  }, [data]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <Layout>
      <h1 className="title">SCANS</h1>
      <div className="max-85 d-flex justify-content-space-between mb-3 margin-auto">
        <Input
          customClass={"search-bar"}
          type="text"
          placeholder={"Rechercher une scanisette, un lieu ou une date..."}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-column list_virus mt-10 ">
        {formattedData ? (
          formattedData.map((s) => (
            <details key={s.id} className="scan_details">
              <summary className="scan_row grid grid-cols-4 align-items-center">
                <span className="ml-10 d-flex align-items-center g-1">
                  <span className="tag_scan">DATE</span>{" "}
                  {formatDate(new Date(s.scan[0].date), "yyyy-mm-dd HH:MM:SS")}
                </span>
                <span className="lh-1-5">
                  <span className="tag_scan">SCANISETTE</span>{" "}
                  {s.scan[0].scanisette.custom_name}
                </span>
                <span className="lh-1-5">
                  <span className="tag_scan">LIEU</span>{" "}
                  {s.scan[0].scanisette.place_group?.name
                    ? s.scan[0].scanisette.place_group?.name
                    : '"Lieu à définir"'}
                </span>
                {/* <span>
                  <span className="tag_scan">ANTIVIRUS</span> {s.scan.length}
                </span> */}
                <span className="mr-1">
                  <span
                    className={`tag_scan ${
                      checkForMultipleAntivirusDetection(s.scan) >= 2
                        ? "b_danger"
                        : checkForMultipleAntivirusDetection(s.scan) === 1
                        ? "b_warning"
                        : "b_ok"
                    }`}
                  >
                    {checkForMultipleAntivirusDetection(s.scan) >= 2
                      ? "VIRUS"
                      : checkForMultipleAntivirusDetection(s.scan) === 1
                      ? "SUSPICION"
                      : "VIRUS"}
                  </span>{" "}
                  {Math.ceil(calculateAverageVirusCount(s.scan))}
                </span>
              </summary>
              <div className="detail_scan">
                {s.scan.map((sc) => (
                  <details key={sc.id} className="scan_details ml-10">
                    <summary className="scan_row grid grid-cols-3 align-items-center">
                      <span className="ml-10 d-flex align-items-center g-1">
                        <span className="tag_scan">ANTIVIRUS</span>{" "}
                        {sc.antivirus}
                      </span>
                      <span>
                        <span className="tag_scan">FICHIERS</span> {sc.nb_files}
                      </span>
                      <span className="mr-10">
                        <span
                          className={`tag_scan ${
                            sc.nb_virus === 0
                              ? "b_ok"
                              : checkForMultipleAntivirusDetection(s.scan) >= 2
                              ? "b_danger"
                              : checkForMultipleAntivirusDetection(s.scan) === 1
                              ? "b_warning"
                              : "b_ok"
                          }`}
                        >
                          {sc.nb_virus === 0
                            ? "VIRUS"
                            : checkForMultipleAntivirusDetection(s.scan) >= 2
                            ? "VIRUS"
                            : checkForMultipleAntivirusDetection(s.scan) === 1
                            ? "SUSPICION"
                            : "VIRUS"}
                        </span>{" "}
                        {sc.nb_virus}
                      </span>
                    </summary>
                    <div className="detail_scan">
                      {sc.virus.length > 0 ? (
                        sc.virus.map((v, index) => (
                          <div key={index} className="virus_detail">
                            <span className="ml-10">
                              <span className="tag_scan">VIRUS</span> {v.name}
                            </span>
                            <span className="mr-10">
                              <span className="tag_scan">FICHIER</span> {v.file}
                            </span>
                          </div>
                        ))
                      ) : (
                        <p className="no_virus">Aucun virus détecté</p>
                      )}
                    </div>
                  </details>
                ))}
              </div>
            </details>
          ))
        ) : (
          <></>
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={totalPage * 10}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </Layout>
  );
}

export default Scans;
