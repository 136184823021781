import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../components/Button.generic";
import InputWithLabel from "../components/InputWithLabel.generic";
import Link from "../components/Link.generic";

import { AuthContext } from "../contexts/AuthContext";
import { getCurrentUser, login } from "../services/User.services";
import { toast } from "react-toastify";
import WelcomeSection from "../components/WelcomeSection.generic";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUser, setAccessToken, setRefreshToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignIn = (e) => {
    e.preventDefault();
    login({ email: email, password: password })
      .then((response) => {
        setAccessToken(response.data.accessToken);
        setRefreshToken(response.data.refresh_token);
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refresh_token);
        localStorage.setItem("userMail", email);
        getCurrentUser().then((resp) => {
          setUser(resp.data);
          toast.success("Connexion établie !");
          navigate("/dashboard");
        })
      })
      .catch((err) => {
        toast.error("Identifiants incorrects");
      });
  };

  // useEffect(() => {
  //   if (localStorage.getItem("accessToken")) {
  //     setEmail(localStorage.getItem("userMail"));
  //     setAccessToken(localStorage.getItem("accessToken"));
  //     setRefreshToken(localStorage.getItem("refreshToken"));
  //     getCurrentUser().then((resp) => setUser(resp.data))
  //     .catch((e) =>
  //       console.error("Error while retrieving the current user", e)
  //     );
  //     // setUser(jwt_decode(localStorage.getItem("accessToken")));
  //     toast.success("Connexion restaurée !");
  //     navigate("/dashboard");
  //   }
  // }, [navigate, setAccessToken, setUser]);

  return (
    <div className="login">
      <WelcomeSection></WelcomeSection>
      <form onSubmit={handleSignIn} className="login-section overflow-auto">
        <img
          src={"/assets/logo.svg"}
          alt="Logo scanisette"
          className="login-logo"
        />
        <h1 className="title_form mt-12 mb-6">CONNEXION</h1>
        <div className="input-container">
          <InputWithLabel
            type="email"
            label="Email"
            value={email}
            placeholder="E-mail"
            customClass="basic_input input-width"
            customClassLabel="input_label mt-8"
            onChange={(e) => setEmail(e.target.value)}
            id="email"
          />
        </div>
        <div className="input-container">
          <InputWithLabel
            type="password"
            label="Mot de passe"
            value={password}
            placeholder="Mot de passe"
            customClass="basic_input input-width"
            customClassLabel="input_label mt-8"
            onChange={(e) => setPassword(e.target.value)}
            id="password"
          />
        </div>
        <Button
          label="Se connecter"
          customClass="basic_button btn-order mt-12"
          type="submit"
        />
        <Link
          onClick={() => {
            navigate("/register");
          }}
          label="Créer un compte"
          customClass="basic_link mt-12"
        />
        <Link
          onClick={() => {
            navigate("/forget_password");
          }}
          label="Mot de passe oublié ?"
          customClass="basic_link mt-12 mb-5"
        />
      </form>
    </div>
  );
}

export default Login;
