export const ItemsNav = [
  {
    id: 0,
    title: "Tableau de bord",
    path: "/dashboard",
    icon: "/assets/dasboard.svg",
    allowedRoles: ["Super Admin"],
  },
  {
    id: 7,
    title: "Mes clients",
    path: "/customers",
    icon: "/assets/clients.svg",
    allowedRoles: ["Super Admin"],
  },
  {
    id: 8,
    title: "Mes antivirus",
    path: "/antivirus",
    icon: "/assets/antivirus.svg",
    allowedRoles: ["Super Admin"],
  },
  // {
  //   id: 10,
  //   title: "Gestion",
  //   path: "/admin",
  //   icon: "/assets/parametres.svg",
  //   allowedRoles: ["Super Admin"],
  // },
  {
    id: 11,
    title: "Utilisateurs",
    path: "/users",
    icon: "/assets/user.svg",
    allowedRoles: ["Super Admin"],
  },
  {
    id: 1,
    title: "Scanisettes",
    path: "/scanisettes",
    icon: "/assets/cle.svg",
    allowedRoles: ["Admin"],
  },
  {
    id: 2,
    title: "Souscription",
    path: "/subscription",
    icon: "/assets/licence.svg",
    allowedRoles: ["Admin"],
  },
  // {
  //   id: 3,
  //   title: "Communication",
  //   path: "/communication",
  //   icon: "/assets/com.svg",
  //   allowedRoles: ["Admin"],
  // },
  // {
  //   id: 4,
  //   title: "Jeux",
  //   path: "/games",
  //   icon: "/assets/jeu.svg",
  //   allowedRoles: ["Admin"],
  // },
  // {
  //   id: 5,
  //   title: "Statistiques",
  //   path: "/stats",
  //   icon: "/assets/stats.svg",
  //   allowedRoles: ["Super Admin", "Admin"],
  // },
  {
    id: 9,
    title: "Mes tickets",
    path: "/tickets",
    icon: "/assets/ticket.svg",
    allowedRoles: ["Super Admin", "Admin"],
  },
  {
    id: 6,
    title: "Paramètres",
    path: "/settings",
    icon: "/assets/parametres.svg",
    allowedRoles: ["Admin"],
  },
  {
    id: 12,
    title: "Scans",
    path: "/scans",
    icon: "/assets/scanisette_logo_menu.svg",
    allowedRoles: ["Admin"],
  },
];

export const ALERTE_TYPE = [
  {
    alert: "Une ou plusieurs bases virale ne sont pas à jour. Eset",
    severity: 1,
    id: 0,
  },
  {
    alert: "La scanisette n'a pas remonté de signal depuis au moins 12 heures.",
    severity: 0,
    id: 1,
  },
  {
    alert: "Une ou plusieurs bases virale ne sont pas à jour. ClamAV",
    severity: 1,
    id: 2,
  },
  {
    alert: "Une ou plusieurs bases virale ne sont pas à jour. WithSecure",
    severity: 1,
    id: 3,
  },
];
