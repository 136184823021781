import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "../components/Button.generic";
import FlatCardCom3 from "../components/FlatCardCom3.generic";
import Modal from "../components/Modal.generic";
import Modal2 from "../components/Modal2.generic";
import Layout from "../layouts/Layout";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "../components/SortableItem";
import { AuthContext } from "../contexts/AuthContext";
import { getAllPlaceGroupsByCustomerId } from "../services/PlaceGroup.services";
import { getScanisetteByCustomerId } from "../services/Scanisette.services";
import Dropdown from "../components/Dropdown.generic";
import Pagination from "../components/Pagination.generic";
import TrueModal from "../components/TrueModal.generic";

const campaigns = [
  {
    id: 12,
    name: "Nom campagne",
    created: "2022-12-16",
    nb_scanisette: 3,
  },
  {
    id: 24,
    name: "Nom campagne",
    created: "2022-12-16",
    nb_scanisette: 5,
  },
  {
    id: 36,
    name: "Nom campagne",
    created: "2022-12-16",
    nb_scanisette: 8,
  },
  {
    id: 48,
    name: "Nom campagne",
    created: "2022-12-16",
    nb_scanisette: 3,
  },
  {
    id: 60,
    name: "Nom campagne",
    created: "2022-12-16",
    nb_scanisette: 2,
  },
  {
    id: 72,
    name: "Nom campagne",
    created: "2022-12-16",
    nb_scanisette: 7,
  },
  {
    id: 84,
    name: "Nom campagne",
    created: "2022-12-16",
    nb_scanisette: 1,
  },
];

let sortByLocationOptions = [];

let sortByScanisetteOptions = [];

function Communication() {
  const [dataCampaign, setDataCampaign] = useState([]);
  const [selectedCom, setSelectedCom] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [manageModal, setManageModal] = useState(false);
  const [display, setDisplay] = useState("all");
  const { user } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);

  let PageSize = +6;

  const customerId = user?.customers[0]?.id;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (customerId) {
      getAllPlaceGroupsByCustomerId(customerId)
        .then(
          (resp) =>
            (sortByLocationOptions = resp.data.map((o) => {
              return {
                value: o.id,
                label: o.name,
              };
            }))
        )
        .catch((e) =>
          console.error(
            "Error while retrieving the place groups by customer id",
            e
          )
        );

      getScanisetteByCustomerId(customerId)
        .then(
          (resp) =>
            (sortByScanisetteOptions = resp.data.map((o) => {
              return {
                value: o.id,
                label: o.custom_name,
              };
            }))
        )
        .catch((e) =>
          console.error(
            "Error while retrieving the scanisettes by customer id",
            e
          )
        );
    }
    setDataCampaign(campaigns);
  }, [customerId]);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return dataCampaign.slice(firstPageIndex, lastPageIndex);
  }, [dataCampaign, currentPage, PageSize]);

  const onDelete = () => {
    setConfirmDeletePopup(false);
  };

  const onSave = () => {
    setManageModal(false);
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;

    if (active.id !== over.id) {
      setSelectedCom((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleDisplayChange = (e) => {
    setDisplay(e.target.id);
  };

  return (
    <Layout>
      <h1 className="title">COMMUNICATION</h1>
      <div className="flex-end max-80">
        <Button
          customClass={
            selectedCom.length !== 0
              ? "basic_button btn-order"
              : "basic_button btn-order disabled"
          }
          label="Gérer"
          type={"button"}
          onClick={() => setManageModal(true)}
          disabled={selectedCom.length !== 0 ? false : true}
        />
        <Button
          customClass={
            selectedCom.length !== 0
              ? "basic_button btn-order"
              : "basic_button btn-order disabled"
          }
          label="Supprimer"
          type={"button"}
          onClick={() => setConfirmDeletePopup(true)}
          disabled={selectedCom.length !== 0 ? false : true}
        />
        <Button
          customClass={"basic_button btn-order"}
          label="Nouvelle communication"
          type={"button"}
          onClick={(e) => console.log(e)}
        />
      </div>
      {currentData.map((c) => (
        <FlatCardCom3
          key={c.id}
          data={c}
          customClass="grid-7-custom communication-flatcardcom3"
          selectedCom={selectedCom}
          setSelectedCom={setSelectedCom}
        />
      ))}
      <Pagination
        currentPage={currentPage}
        totalCount={dataCampaign.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      
      <TrueModal
        show={confirmDeletePopup}
        onClose={() => setConfirmDeletePopup(false)}
        classNameModalWrapper={
          "true-modal position-fixed b-background-modal d-flex align-items-center justify-content-center z-1000"
        }
        classNameModalContent={"b-modal wpx500"}
      >
        <div className="p-3">
          <div className="flex-end">
            <img
              src="/assets/annuler.svg"
              alt="Icône fermeture"
              onClick={() => setConfirmDeletePopup(false)}
            />
          </div>
          <h3 className="modal-title">Supprimer</h3>
        </div>

        <div className="p-3">
          Êtes-vous sûr de vouloir supprimer la(les) campagne(s) sélectionnée(s)
          ?
        </div>

        <div className="p-3">
          <Button
            customClass={"basic_button btn-order"}
            label="Confirmer la suppression"
            type={"button"}
            onClick={onDelete}
          />
        </div>
      </TrueModal>
      {/* <Modal2
        title="Gérer les communications"
        show={manageModal}
        onClose={() => setManageModal(false)}
        onConfirm={onSave}
      >
        <div className="text-center mb-6">
          <span className="bold">
            Sélectionner la/les scanisette(s) sur lesquelles vous soushaitez
            ajouter des communications
          </span>
        </div>
        <div className="card-column">
          <label>
            <input
              type="radio"
              name="display"
              id="all"
              checked={display === "all"}
              onChange={handleDisplayChange}
            />
            Affichage sur toutes les scanisettes
          </label>
          <label>
            <input
              type="radio"
              name="display"
              id="location"
              checked={display === "location"}
              onChange={handleDisplayChange}
            />
            Affichage sur toutes les scanisettes de lieu(x)
          </label>
          {display === "location" && (
            <Dropdown
              isMulti
              onChange={(value) => console.log(value)}
              placeHolder={"Sélectionnez un ou plusieurs lieux..."}
              options={sortByLocationOptions}
            />
          )}
          <label>
            <input
              type="radio"
              name="display"
              id="selection"
              checked={display === "selection"}
              onChange={handleDisplayChange}
            />
            Affichage sur une sélection de scanisettes
          </label>
          {display === "selection" && (
            <Dropdown
              isMulti
              onChange={(value) => console.log(value)}
              placeHolder={"Sélectionnez une ou plusieurs scanisettes..."}
              options={sortByScanisetteOptions}
            />
          )}
        </div>
        <hr />
        <span className="bold">Communication(s) par ordre de rotation</span>

        <p>
          Modifier l'ordre de rotation des communications sur les scanisettes en
          faisant glisser les lignes ci-dessous via l'icône{" "}
          <img src="/assets/move.svg" alt="Icône déplacement" />
        </p>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={selectedCom}
            strategy={verticalListSortingStrategy}
          >
            {selectedCom.map((id) => (
              <SortableItem key={id} id={id} />
            ))}
          </SortableContext>
        </DndContext>
      </Modal2> */}
      <TrueModal
        show={manageModal}
        onClose={() => setManageModal(false)}
        classNameModalWrapper={
          "true-modal position-fixed b-background-modal d-flex align-items-center justify-content-end z-1000"
        }
        classNameModalContent={"b-modal wpx500 h100"}
      >
        <div className="p-3">
          <div className="flex-end">
            <img
              src="/assets/annuler.svg"
              alt="Icône fermeture"
              onClick={() => setManageModal(false)}
            />
          </div>
          <h3 className="modal-title text-center">Gérer les communications</h3>
        </div>

        <div className="p-3 max-h75 overflow-y-scroll-hidden">
          <div className="text-center mb-6">
            <span className="bold">
              Sélectionner la/les scanisette(s) sur lesquelles vous soushaitez
              ajouter des communications
            </span>
          </div>
          <div className="card-column">
            <label>
              <input
                type="radio"
                name="display"
                id="all"
                checked={display === "all"}
                onChange={handleDisplayChange}
              />
              Affichage sur toutes les scanisettes
            </label>
            <label>
              <input
                type="radio"
                name="display"
                id="location"
                checked={display === "location"}
                onChange={handleDisplayChange}
              />
              Affichage sur toutes les scanisettes de lieu(x)
            </label>
            {display === "location" && (
              <Dropdown
                isMulti
                onChange={(value) => console.log(value)}
                placeHolder={"Sélectionnez un ou plusieurs lieux..."}
                options={sortByLocationOptions}
              />
            )}
            <label>
              <input
                type="radio"
                name="display"
                id="selection"
                checked={display === "selection"}
                onChange={handleDisplayChange}
              />
              Affichage sur une sélection de scanisettes
            </label>
            {display === "selection" && (
              <Dropdown
                isMulti
                onChange={(value) => console.log(value)}
                placeHolder={"Sélectionnez une ou plusieurs scanisettes..."}
                options={sortByScanisetteOptions}
              />
            )}
          </div>
          <hr />
          <span className="bold">Communication(s) par ordre de rotation</span>

          <p>
            Modifier l'ordre de rotation des communications sur les scanisettes
            en faisant glisser les lignes ci-dessous via l'icône{" "}
            <img src="/assets/move.svg" alt="Icône déplacement" />
          </p>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={selectedCom}
              strategy={verticalListSortingStrategy}
            >
              {selectedCom.map((id) => (
                <SortableItem key={id} id={id} />
              ))}
            </SortableContext>
          </DndContext>
        </div>

        <div className="p-3 d-flex justify-content-center">
          <Button
            customClass={"basic_button btn-order"}
            label="Enregistrer"
            type={"button"}
            onClick={onSave}
          />
        </div>
      </TrueModal>
    </Layout>
  );
}

export default Communication;
