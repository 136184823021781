import React, { useContext, useEffect, useMemo, useState } from "react";
// import { useNavigate } from "react-router-dom";
import Button from "../components/Button.generic";
import Input from "../components/Input.generic";
import Modal from "../components/Modal.generic";
import Pagination from "../components/Pagination.generic";
import Table from "../components/Table.generic";
import { AuthContext } from "../contexts/AuthContext";
import Layout from "../layouts/Layout";
import {
  deleteMultipleUsers,
  getUsersByCustomerId,
} from "../services/User.services";
import TabContent from "../components/TabContent.generic";
import TabNavItem from "../components/TabNavItem.generic";
import SettingsUserTableRow from "./Table/SettingsUserTableRow";
import {
  getCustomerById,
  updateCustomerById,
} from "../services/Customer.services";
import { getLogByCustomerId } from "../services/Log.services";
import SettingsLogTableRow from "./Table/SettingsLogTableRow";
import Modal5 from "../components/Modal5.generic";
import {
  addScreenPlanningEvent,
  deleteScreenPlanningEvent,
} from "../services/ScreenPlanning.services";
import { toast } from "react-toastify";
import TrueModal from "../components/TrueModal.generic";
import InputWithLabel from "../components/InputWithLabel.generic";

const tableHeaderUser = [" ", "Nom", "Prénom", "E-mail", " "];

const tableHeaderLogs = ["Date", "Message"];

const weekdays = [
  { value: "Lundi", label: "Lundi" },
  { value: "Mardi", label: "Mardi" },
  { value: "Mercredi", label: "Mercredi" },
  { value: "Jeudi", label: "Jeudi" },
  { value: "Vendredi", label: "Vendredi" },
  { value: "Samedi", label: "Samedi" },
  { value: "Dimanche", label: "Dimanche" },
];

const hours = [
  { value: "00:00", label: "00:00" },
  { value: "01:00", label: "01:00" },
  { value: "02:00", label: "02:00" },
  { value: "03:00", label: "03:00" },
  { value: "04:00", label: "04:00" },
  { value: "05:00", label: "05:00" },
  { value: "06:00", label: "06:00" },
  { value: "07:00", label: "07:00" },
  { value: "08:00", label: "08:00" },
  { value: "09:00", label: "09:00" },
  { value: "10:00", label: "10:00" },
  { value: "11:00", label: "11:00" },
  { value: "12:00", label: "12:00" },
  { value: "13:00", label: "13:00" },
  { value: "14:00", label: "14:00" },
  { value: "15:00", label: "15:00" },
  { value: "16:00", label: "16:00" },
  { value: "17:00", label: "17:00" },
  { value: "18:00", label: "18:00" },
  { value: "19:00", label: "19:00" },
  { value: "20:00", label: "20:00" },
  { value: "21:00", label: "21:00" },
  { value: "22:00", label: "22:00" },
  { value: "23:00", label: "23:00" },
  { value: "24:00", label: "24:00" },
];

// const events = [
//   {
//     id: 1,
//     day: "Lundi",
//     hour_start: "00:00",
//     hour_end: "08:00",
//   },
//   {
//     id: 2,
//     day: "Lundi",
//     hour_start: "19:00",
//     hour_end: "24:00",
//   },
//   {
//     id: 3,
//     day: "Mardi",
//     hour_start: "00:00",
//     hour_end: "08:00",
//   },
//   {
//     id: 4,
//     day: "Mardi",
//     hour_start: "19:00",
//     hour_end: "24:00",
//   },
//   {
//     id: 5,
//     day: "Mercredi",
//     hour_start: "00:00",
//     hour_end: "08:00",
//   },
//   {
//     id: 6,
//     day: "Mercredi",
//     hour_start: "19:00",
//     hour_end: "24:00",
//   },
//   {
//     id: 7,
//     day: "Jeudi",
//     hour_start: "00:00",
//     hour_end: "08:00",
//   },
//   {
//     id: 8,
//     day: "Jeudi",
//     hour_start: "19:00",
//     hour_end: "24:00",
//   },
//   {
//     id: 9,
//     day: "Vendredi",
//     hour_start: "00:00",
//     hour_end: "08:00",
//   },
//   {
//     id: 10,
//     day: "Vendredi",
//     hour_start: "19:00",
//     hour_end: "24:00",
//   },
//   {
//     id: 11,
//     day: "Samedi",
//     hour_start: "00:00",
//     hour_end: "08:00",
//   },
//   {
//     id: 12,
//     day: "Samedi",
//     hour_start: "19:00",
//     hour_end: "24:00",
//   },
//   {
//     id: 13,
//     day: "Dimanche",
//     hour_start: "00:00",
//     hour_end: "08:00",
//   },
//   {
//     id: 14,
//     day: "Dimanche",
//     hour_start: "19:00",
//     hour_end: "24:00",
//   },
// ];

function Settings() {
  const [dataUser, setDataUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("tab1");
  const [virusDetection, setVirusDetection] = useState("");
  const [virusSuspicion, setVirusSuspicion] = useState("");
  const [dataLog, setDataLog] = useState([]);
  const [currentPageLog, setCurrentPageLog] = useState(1);
  const [modalScreenEvent, setModalScreenEvent] = useState(false);
  const [dayEvent, setDayEvent] = useState("");
  const [hourStartEvent, setHourStartEvent] = useState("");
  const [hourEndEvent, setHourEndEvent] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [events, setEvents] = useState([]);
  const [exitCode, setExitCode] = useState("");
  const [adminCode, setAdminCode] = useState("");
  // const navigate = useNavigate();

  const customerId = user?.customers[0]?.id;

  let PageSize = +12;

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    if (customerId) {
      getUsersByCustomerId(customerId)
        .then((resp) => {
          const users = resp.data.map((user) => {
            return {
              id: user?.id,
              lastname: user?.name,
              firstname: user?.firstname,
              email: user?.email,
              // role: user.role.name,
              // role_id: user.role.id, Necessary to update the role in SettingsUserTableRow
            };
          });
          setDataUser(users);
        })
        .catch((e) =>
          console.error("Error while retrieving the users by customer ID", e)
        );

      getCustomerById(customerId)
        .then((resp) => {
          setVirusDetection(
            resp.data.message_virus && resp.data.message_virus !== " "
              ? resp.data.message_virus
              : ""
          );
          setVirusSuspicion(
            resp.data.message_suspicion && resp.data.message_suspicion !== " "
              ? resp.data.message_suspicion
              : ""
          );
          setEvents(resp.data.screen_planning);
        })
        .catch((e) => console.error("Error while retrieving the customer", e));

      getLogByCustomerId(customerId)
        .then((resp) => {
          const logs = resp.data.map((log) => {
            return {
              id: log.id,
              date: log.date,
              message: log.message,
            };
          });
          setDataLog(
            logs.sort((a, b) => {
              return a.date > b.date ? -1 : 1;
            })
          );
        })
        .catch((e) =>
          console.error("Error while retrieving the logs by customer ID", e)
        );
    }
  };

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    // The following lines are to prevent the following behavior : if the user is on page 2 and start to search for a user the results won't be shown as they are displayed on page 1 but the current page is 2
    const totalPageCount = Math.ceil(
      dataUser.filter((row) => {
        if (query === "") {
          return row;
        } else if (row.lastname.toLowerCase().includes(query.toLowerCase())) {
          return row;
        } else {
          return false;
        }
      }).length / PageSize
    );
    if (totalPageCount < currentPage) {
      setCurrentPage(1);
    }
    // End
    setCurrentData(
      dataUser
        .filter((row) => {
          if (query === "") {
            return row;
          } else if (row.lastname.toLowerCase().includes(query.toLowerCase())) {
            return row;
          } else {
            return false;
          }
        })
        .slice(firstPageIndex, lastPageIndex)
    );
  }, [dataUser, currentPage, PageSize, query]);

  // const handleNewUser = () => {
  //   navigate("/add/user");
  // };

  const currentDataLog = useMemo(() => {
    const firstPageIndex = (currentPageLog - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(dataLog.length / PageSize);
    if (totalPageCount < currentPageLog) {
      setCurrentPageLog(1);
    }
    return dataLog.slice(firstPageIndex, lastPageIndex);
  }, [dataLog, currentPageLog, PageSize]);

  const onDelete = () => {
    if (selectedUser.length > 0) {
      deleteMultipleUsers({ object_ids: selectedUser.map((s) => +s) })
        .then(() => {
          getDatas();
          setConfirmDeletePopup(false);
          setSelectedUser([]);
        })
        .catch((e) => console.error("Error while deleting multiple users", e));
    }
  };

  const handleSave = () => {
    if (customerId) {
      if (virusDetection.trim() !== "" && virusSuspicion.trim() !== "") {
        updateCustomerById(customerId, {
          message_virus: virusDetection,
          message_suspicion: virusSuspicion,
        })
          .then((resp) => {
            toast.success("Message(s) de notification enregistré(s)");
          })
          .catch((e) => console.error("Error while updating the customer", e));
      }
      if (virusDetection.trim() !== "" && virusSuspicion.trim() === "") {
        updateCustomerById(customerId, {
          message_virus: virusDetection,
        })
          .then((resp) => {
            toast.success("Message(s) de notification enregistré(s)");
          })
          .catch((e) => console.error("Error while updating the customer", e));
      }
      if (virusSuspicion.trim() !== "" && virusDetection.trim() === "") {
        updateCustomerById(customerId, {
          message_suspicion: virusSuspicion,
        })
          .then((resp) => {
            toast.success("Message(s) de notification enregistré(s)");
          })
          .catch((e) => console.error("Error while updating the customer", e));
      }
    }
  };

  const handleReset = () => {
    if (customerId) {
      updateCustomerById(customerId, {
        message_virus: " ",
        message_suspicion: " ",
      })
        .then((resp) => {
          getDatas();
          toast.success("Messages de notification réinitialisés");
        })
        .catch((e) => console.error("Error while resetting the customer", e));
    }
  };

  const handleSaveCode = () => {
    if (customerId) {
      if (exitCode && adminCode) {
        updateCustomerById(customerId, {
          exit_code: exitCode,
          admin_code: adminCode,
        })
          .then((resp) => {
            toast.success("Nouveaux codes enregistrés");
          })
          .catch((e) => console.error("Error while updating the customer", e));
      }
      if (exitCode && !adminCode) {
        updateCustomerById(customerId, {
          exit_code: exitCode,
        })
          .then((resp) => {
            toast.success("Nouveau code enregistré");
          })
          .catch((e) => console.error("Error while updating the customer", e));
      }
      if (!exitCode && adminCode) {
        updateCustomerById(customerId, {
          admin_code: adminCode,
        })
          .then((resp) => {
            toast.success("Nouveau code enregistré");
          })
          .catch((e) => console.error("Error while updating the customer", e));
      }
    }
  };

  const handleResetCode = () => {
    if (customerId) {
      updateCustomerById(customerId, {
        exit_code: "1234",
        admin_code: "1235",
      })
        .then((resp) => {
          setExitCode("");
          setAdminCode("");
          toast.success("Codes réinitialisés");
        })
        .catch((e) => console.error("Error while resetting the customer", e));
    }
  };

  const isSlotAvailable = (day, start, end) => {
    const overlappingEvent = events.find(
      (event) =>
        event.day === day &&
        ((event.hour_start <= start && event.hour_end > start) ||
          (event.hour_start < end && event.hour_end >= end) ||
          (event.hour_start >= start && event.hour_end <= end))
    );
    return !overlappingEvent;
  };

  const addScreenEvent = () => {
    if (
      dayEvent &&
      hourStartEvent &&
      hourEndEvent &&
      hourEndEvent > hourStartEvent
    ) {
      if (isSlotAvailable(dayEvent, hourStartEvent, hourEndEvent)) {
        // Le créneau est disponible, ajoutez-le au tableau events
        const newEvent = {
          day: dayEvent,
          hour_start: hourStartEvent,
          hour_end: hourEndEvent,
          customer_id: customerId,
        };
        setWarningMessage("");
        setModalScreenEvent(false);
        addScreenPlanningEvent(newEvent)
          .then((resp) => getDatas())
          .catch((e) => console.error("Error while adding a screen event", e));
        // setEvents([...events, newEvent]); // Mettez à jour le tableau events avec le nouvel événement
      } else {
        setWarningMessage("Créneau déjà pris ou chevauche un autre créneau !");
      }
    } else {
      setWarningMessage(
        "Veuillez sélectionner un jour et des heures valides !"
      );
    }
  };

  const deleteScreenEvent = (id) => {
    deleteScreenPlanningEvent(id)
      .then((resp) => getDatas())
      .catch((e) => console.error("Error while deleting a screen event", e));
  };

  return (
    <Layout>
      <h1 className="title">Paramètres</h1>
      <ul className="nav-items">
        <TabNavItem
          title="Utilisateurs"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Notifications"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Logs"
          id="tab3"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Ecran - mise en veille"
          id="tab4"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Codes"
          id="tab5"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <TabContent id="tab1" activeTab={activeTab}>
        <div className="display-menu-customers">
          <Input
            customClass={"search-bar"}
            type="text"
            placeholder={"Rechercher un nom"}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="display-menu-right">
            <Button
              customClass={
                selectedUser.length !== 0
                  ? "basic_button btn-order"
                  : "basic_button btn-order disabled"
              }
              label="Supprimer un utilisateur"
              type={"button"}
              onClick={() => setConfirmDeletePopup(true)}
              disabled={selectedUser.length !== 0 ? false : true}
            />
            {/* <Button
            customClass={"basic_button btn-order"}
            label="Ajouter un utilisateur"
            type={"button"}
            onClick={handleNewUser}
          /> */}
          </div>
        </div>
        <Table
          theadData={tableHeaderUser}
          tbodyData={currentData}
          customClass={"table-scanisettes"}
          TableRow={SettingsUserTableRow}
          selectedRow={selectedUser}
          setSelectedRow={setSelectedUser}
          getDatas={getDatas}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={
            dataUser.filter((row) => {
              if (query === "") {
                return row;
              } else if (
                row.lastname.toLowerCase().includes(query.toLowerCase())
              ) {
                return row;
              } else {
                return false;
              }
            }).length
          }
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
       
        <TrueModal
          show={confirmDeletePopup}
          onClose={() => setConfirmDeletePopup(false)}
          classNameModalWrapper={
            "true-modal position-fixed b-background-modal d-flex align-items-center justify-content-center z-1000"
          }
          classNameModalContent={"b-modal wpx500"}
        >
          <div className="p-3">
            <div className="flex-end">
              <img
                src="/assets/annuler.svg"
                alt="Icône fermeture"
                onClick={() => setConfirmDeletePopup(false)}
              />
            </div>
            <h3 className="modal-title">Supprimer</h3>
          </div>

          <div className="p-3">
            Êtes-vous sûr de vouloir supprimer le(s) utilisateur(s)
            sélectionné(s) ?
          </div>

          <div className="p-3">
            <Button
              customClass={"basic_button btn-order"}
              label="Confirmer la suppression"
              type={"button"}
              onClick={onDelete}
            />
          </div>
        </TrueModal>
      </TabContent>

      <TabContent id="tab2" activeTab={activeTab}>
        <div className="d-flex f-column align-items-center g-5 pr-8 pl-8">
          <div className="d-flex f-column">
            <h2>Détection de Virus</h2>
            <label htmlFor="detection-virus">
              Veuillez renseigner le message à afficher en cas de{" "}
              <strong>détection</strong> de virus :
            </label>

            <textarea
              id="detection-virus"
              name="detection-virus"
              rows="5"
              cols="35"
              placeholder="Virus détecté. Veuillez contacter l'administrateur réseau."
              onChange={(e) => setVirusDetection(e.target.value)}
              value={virusDetection}
            ></textarea>
          </div>

          <div className="d-flex f-column">
            <h2>Suspicion de Virus</h2>
            <label htmlFor="suspicion-virus">
              Veuillez renseigner le message à afficher en cas de{" "}
              <strong>suspicion</strong> de virus :
            </label>

            <textarea
              id="suspicion-virus"
              name="suspicion-virus"
              rows="5"
              cols="35"
              placeholder="Suspicion de virus. Veuillez contacter l'administrateur réseau."
              onChange={(e) => setVirusSuspicion(e.target.value)}
              value={virusSuspicion}
            ></textarea>
          </div>

          <div className="flex g-2 mb-3">
            <Button
              label={"Enregistrer"}
              customClass="basic_button btn-order"
              onClick={handleSave}
            />
            <Button
              label={"Réinitialiser"}
              customClass="basic_button btn-order"
              onClick={handleReset}
            />
          </div>
        </div>
      </TabContent>
      <TabContent id="tab3" activeTab={activeTab}>
        <Table
          theadData={tableHeaderLogs}
          tbodyData={currentDataLog}
          customClass={"table-scanisettes"}
          TableRow={SettingsLogTableRow}
        />
        <Pagination
          currentPage={currentPageLog}
          totalCount={dataLog.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPageLog(page)}
        />
      </TabContent>
      <TabContent id="tab4" activeTab={activeTab}>
        <Button
          label={"Ajouter un créneau"}
          customClass="basic_button btn-order mb-5 ml-10"
          onClick={() => setModalScreenEvent(true)}
        />
        <div className="calendar-container d-flex mb-10">
          <div className="hour-column">
            <div className="hour-name">Heures</div>
            <div className="hour" style={{ height: "25px" }}>
              00:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              01:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              02:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              03:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              04:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              05:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              06:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              07:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              08:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              09:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              10:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              11:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              12:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              13:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              14:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              15:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              16:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              17:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              18:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              19:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              20:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              21:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              22:00
            </div>
            <div className="hour" style={{ height: "25px" }}>
              23:00
            </div>
          </div>
          {weekdays.map((day) => (
            <div key={day.value} className="day-column">
              <div className="day-name">{day.value}</div>
              {events
                .filter((e) => e.day === day.value)
                .map((e) => (
                  <div key={e.id} className="event-container">
                    <div
                      key={e.id}
                      className="event"
                      style={{
                        top: `calc(${+e.hour_start.split(
                          ":"
                        )[0]} * 25px + 40px)`,
                        height: `calc(${
                          +e.hour_end.split(":")[0] -
                          +e.hour_start.split(":")[0]
                        } * 25px)`,
                      }}
                    >
                      {" "}
                      <div
                        className="event-relative"
                        onClick={() => modalScreenEvent(true)}
                      >
                        Mise en veille
                        <div
                          className="delete-btn-event"
                          onClick={() => deleteScreenEvent(e.id)}
                        >
                          &#10006;
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
        
        <TrueModal
          show={modalScreenEvent}
          onClose={() => {
            setModalScreenEvent(false);
            setWarningMessage("");
          }}
          classNameModalWrapper={
            "true-modal position-fixed b-background-modal d-flex align-items-center justify-content-center z-1000"
          }
          classNameModalContent={"b-modal wpx400"}
        >
          <div className="p-3">
            <div className="flex-end">
              <img
                src="/assets/annuler.svg"
                alt="Icône fermeture"
                onClick={() => {
                  setModalScreenEvent(false);
                  setWarningMessage("");
                }}
              />
            </div>
            <h3 className="modal-title">Ajouter un créneau de mise en veille</h3>
          </div>

          <div className="p-3">
          <div>Choisir un jour :</div>
          <select
            name="dayEvent"
            className={`select-menu-event`}
            onChange={(e) => setDayEvent(e.target.value)}
          >
            <option value="">--Choisir un jour--</option>
            {weekdays.map((day) => (
              <option key={day.value} value={day.value}>
                {day.label}
              </option>
            ))}
          </select>

          <div className="mt-6">Choisir une heure de début :</div>
          <select
            name="hourStartEvent"
            className={`select-menu-event`}
            onChange={(e) => setHourStartEvent(e.target.value)}
          >
            <option value="">--Choisir une heure de début--</option>
            {hours
              .filter((hour) => hour.value !== "24:00")
              .map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
          </select>

          <div className="mt-6">Choisir une heure de fin :</div>
          <select
            name="hourEndEvent"
            className={`select-menu-event`}
            onChange={(e) => setHourEndEvent(e.target.value)}
          >
            <option value="">--Choisir une heure de fin--</option>
            {hours
              .filter((hour) => hour.value !== "00:00")
              .map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
          </select>
          {warningMessage ? (
            <p className="warning-message mt-8">{warningMessage}</p>
          ) : (
            <></>
          )}
          </div>

          <div className="p-3">
          <Button
            customClass={"basic_button btn-order"}
            label="Confirmer le créneau"
            type={"button"}
            onClick={addScreenEvent}
          />
          </div>
        </TrueModal>
      </TabContent>

      <TabContent id="tab5" activeTab={activeTab}>
        <div className="d-flex f-column align-items-center g-5 pr-8 pl-8">
          <div className="d-flex f-column w35">
            <h2>Code pour sortir du mode plein écran</h2>
            <InputWithLabel
              type="text"
              label="Veuillez renseigner le code à effectuer pour sortir du mode plein écran"
              value={exitCode}
              placeholder="1234"
              customClass="basic_input w100"
              customClassLabel="input_label mt-1"
              onChange={(e) => setExitCode(e.target.value)}
              id="exit_code"
            />
          </div>

          <div className="d-flex f-column w35 mt-3">
            <h2>Code pour accéder aux paramètres</h2>
            <InputWithLabel
              type="text"
              label="Veuillez renseigner le code à effectuer pour accéder aux paramètres"
              value={adminCode}
              placeholder="1235"
              customClass="basic_input w100"
              customClassLabel="input_label mt-1"
              onChange={(e) => setAdminCode(e.target.value)}
              id="admin_code"
            />
          </div>

          <div className="flex g-2 mb-3">
            <Button
              label={"Enregistrer"}
              customClass="basic_button btn-order"
              onClick={handleSaveCode}
            />
            <Button
              label={"Réinitialiser"}
              customClass="basic_button btn-order"
              onClick={handleResetCode}
            />
          </div>
        </div>
      </TabContent>
    </Layout>
  );
}

export default Settings;
