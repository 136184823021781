import React, { useContext } from "react";
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Admin from "../pages/Admin";
import AntiVirus from "../pages/AntiVirus";
import Campaign from "../pages/Campaign";
import Communication from "../pages/Communication";
import CreateCustomer from "../pages/CreateCustomer";
import CreateUser from "../pages/CreateUser";
import Customer from "../pages/Customer";
import Customers from "../pages/Customers";
import { AuthContext } from "../contexts/AuthContext";
import Dashboard from "../pages/Dashboard";
import DashboardAdmin from "../pages/DashboardAdmin";
// import DashboardAdmin from "../pages/DashboardAdmin";
import ForgetPassword from "../pages/ForgetPassword";
import Games from "../pages/Games";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Scanisette from "../pages/Scanisette";
import Scanisettes from "../pages/Scanisettes";
import Settings from "../pages/Settings";
import Stats from "../pages/Stats";
import Subscription from "../pages/Subscription";
import CreateTicket from "../pages/CreateTicket";
import Ticket from "../pages/Ticket";
import Tickets from "../pages/Tickets";
import Users from "../pages/Users";
import CheckAccess from "../components/CheckAccess.component";
import ResetPassword from "../pages/ResetPassword";
import Alerts from "../pages/Alerts";
import Scans from "../pages/Scans";

const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

const Routing = () => {
  const { user } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <CheckAccess></CheckAccess>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route
          path="/dashboard_admin"
          element={
            <ProtectedRoute isAllowed={user?.role.includes("ADMIN")}>
              <DashboardAdmin />
            </ProtectedRoute>
          }
        /> */}
        <Route path="/dashboard_admin" element={<DashboardAdmin />} />
        <Route
          path="/scanisettes"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight >= 101}
              redirectPath="/dashboard"
            >
              <Scanisettes />
            </ProtectedRoute>
          }
        />
        <Route path="/scanisettes/:scanisetteId" element={<Scanisette />} />
        <Route
          path="/subscription"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight >= 101}
              redirectPath="/dashboard"
            >
              <Subscription />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight <= 100}
              redirectPath="/dashboard"
            >
              <Customers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers/:customerId"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight <= 100}
              redirectPath="/dashboard"
            >
              <Customer />
            </ProtectedRoute>
          }
        />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/tickets/:ticketId" element={<Ticket />} />
        <Route
          path="/antivirus"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight <= 100}
              redirectPath="/dashboard"
            >
              <AntiVirus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight <= 100}
              redirectPath="/dashboard"
            >
              <Users />
            </ProtectedRoute>
          }
        />
        <Route path="/stats" element={<Stats />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight <= 100}
              redirectPath="/dashboard"
            >
              <Admin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/communication"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight >= 101}
              redirectPath="/dashboard"
            >
              <Communication />
            </ProtectedRoute>
          }
        />
        <Route
          path="/communication/:campaignId"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight >= 101}
              redirectPath="/dashboard"
            >
              <Campaign />
            </ProtectedRoute>
          }
        />
        <Route
          path="/games"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight >= 101}
              redirectPath="/dashboard"
            >
              <Games />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight >= 101}
              redirectPath="/dashboard"
            >
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add/customer"
          element={
            <ProtectedRoute
              isAllowed={user?.role?.weight <= 100}
              redirectPath="/dashboard"
            >
              <CreateCustomer />
            </ProtectedRoute>
          }
        />
        <Route path="/add/user" element={<CreateUser />} />
        <Route path="/add/ticket" element={<CreateTicket />} />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="/scans" element={<Scans />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
