import React from "react";
import { expired, expiresSoon, licenceScanisette } from "../utils/func";

function CardScanisette({ dataScanisette }) {
  return (
    <div
      className={`card_scanisette ${
        dataScanisette.state === "inactive"
          ? "inactive"
          : dataScanisette.state === "active"
          ? "active"
          : "almost-inactive"
      }`}
    >
      <div className="card_icon">
        <img src={"/assets/scanisette.svg"} alt="Icon card" />
      </div>

      <br />

      <div className="card_info">
        <p>
          <strong>{dataScanisette.name}</strong>
          <span className="hostname">({dataScanisette.hostname})</span>
        </p>
        <p>{dataScanisette.area ? dataScanisette.area : '"Lieu à définir"'}</p>
        <span
          className={
            dataScanisette.state === "active"
              ? "active"
              : dataScanisette.state === "inactive"
              ? "inactive"
              : "almost-inactive"
          }
        >
          {dataScanisette.state === "active"
            ? "Active"
            : dataScanisette.state === "inactive"
            ? "Inactive"
            : "Inactive depuis peu"}
        </span>{" "}
        {licenceScanisette(dataScanisette.expires)}
        <div className="mt-3">
          <img
            src={dataScanisette.uri_image_no_comm ? `${process.env.REACT_APP_API_URL}/${dataScanisette.uri_image_no_comm}` : `${process.env.REACT_APP_API_URL}/background_scan_scanisette.png`}
            alt="Background image"
            className="object-contain hpx125 wpx125"
          />
        </div>
        <hr />
        <p>
          Scans: <span className="info">{dataScanisette.scans}</span>
        </p>
        <p>
          Virus détecté: <span className="info">{dataScanisette.virus}</span>
        </p>
      </div>
    </div>
  );
}

export default CardScanisette;
