import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import Button from "../components/Button.generic";
import Input from "../components/Input.generic";
import Modal from "../components/Modal.generic";
import Pagination from "../components/Pagination.generic";
import Table from "../components/Table.generic";
import Layout from "../layouts/Layout";
import TicketTableRow from "./Table/TicketTableRow";
import { getTicketsByCustomerId, deleteTicketById } from "../services/Ticket.services";
import { useNavigate, useLocation } from "react-router-dom";
import TrueModal from "../components/TrueModal.generic";

const tableHeaderTicket = [
  " ",
  "N° ticket",
  "Nom",
  "Date d'ouverture",
  "Contact",
  "Dernières MAJ",
  "Statut",
  " ",
];

function Tickets() {
  const location = useLocation ();
  let customerId = location?.state?.customer;
  const { user } = useContext(AuthContext);

  const [dataTicket, setDataTicket] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [selfRef, setSelfRef] = useState (0);
  const navigate = useNavigate();

  let PageSize = +12;

  if (!customerId) {
    customerId = user?.customers[0]?.id;  // This is not OK... Work correct only if user has ONE customer
  }

  useEffect(() => {
    if (customerId) {
      getTicketsByCustomerId (customerId)
       .then((resp) => {
          const tickets = resp.data.map((ticket) => {
            return {
              id: ticket?.id,
              status: ticket?.status,
              name: ticket?.title,
              text: ticket?.content,
              contact: ticket?.creatorEmail,
              date: ticket?.createOn,
              update: ticket?.updateOn
            };
          });
          setDataTicket(tickets);
        })
        .catch((e) => console.error("Error while retrieving the tickets", e));
    }
  }, [selfRef, customerId]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    // The following lines are to prevent the following behavior : if the user is on page 2 and start to search for a ticket the results won't be shown as they are displayed on page 1 but the current page is 2
    const totalPageCount = Math.ceil(
      dataTicket.filter((row) => {
        if (query === "") {
          return row;
        } else if (row.name.toLowerCase().includes(query.toLowerCase())) {
          return row;
        } else {
          return false;
        }
      }).length / PageSize
    );
    if (totalPageCount < currentPage) {
      setCurrentPage(1);
    }
    // End
    setCurrentData(
      dataTicket
        .filter((row) => {
          if (query === "") {
            return row;
          } else if (row.name.toLowerCase().includes(query.toLowerCase())) {
            return row;
          } else {
            return false;
          }
        })
        .slice(firstPageIndex, lastPageIndex)
    );
  }, [dataTicket, currentPage, PageSize, query]);

  const handleNewTicket = (e) => {
    navigate ('/add/ticket', { state: { customer: customerId}});
  };

  const onDelete = () => {
    let tickets = selectedTicket.length;
    if (tickets > 0) {
      setConfirmDeletePopup(false);
      // Remove the selected tickets
      selectedTicket.forEach((t) => {
        for (let i = 0; i < dataTicket.length; i++) {
          if (dataTicket[i].id === +t) {
            // sync refresh after LAST DELETE competed
            tickets--;
            deleteTicketById (dataTicket[i].id).then(() => { if (!tickets) { setSelfRef (selfRef+1)}} );
            // dataTicket[i].id is unique! We stop looking
            break;
          }
        }
      });
      setSelectedTicket([]);
    }
  };

  return (
    <Layout>
      <h1 className="title">MES TICKETS</h1>
      <div className="display-menu-customers">
        <Input
          customClass={"search-bar"}
          type="text"
          placeholder={"Rechercher un nom de ticket"}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="display-menu-right">
          <Button
            customClass={
              selectedTicket.length !== 0
                ? "basic_button btn-order"
                : "basic_button btn-order disabled"
            }
            label="Supprimer"
            type={"button"}
            onClick={() => setConfirmDeletePopup(true)}
            disabled={selectedTicket.length !== 0 ? false : true}
          />
          <Button
            customClass={"basic_button btn-order"}
            label="Nouveau ticket"
            type={"button"}
            onClick={handleNewTicket}
          />
        </div>
      </div>
      <Table
        theadData={tableHeaderTicket}
        tbodyData={currentData}
        customClass={"table-scanisettes"}
        TableRow={TicketTableRow}
        selectedRow={selectedTicket}
        setSelectedRow={setSelectedTicket}
        customerId={customerId}
      />
      <Pagination
        currentPage={currentPage}
        totalCount={
          dataTicket.filter((row) => {
            if (query === "") {
              return row;
            } else if (row.name.toLowerCase().includes(query.toLowerCase())) {
              return row;
            } else {
              return false;
            }
          }).length
        }
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      
      <TrueModal
          show={confirmDeletePopup}
          onClose={() => setConfirmDeletePopup(false)}
          classNameModalWrapper={
            "true-modal position-fixed b-background-modal d-flex align-items-center justify-content-center z-1000"
          }
          classNameModalContent={"b-modal wpx500"}
        >
          <div className="p-3">
            <div className="flex-end">
              <img
                src="/assets/annuler.svg"
                alt="Icône fermeture"
                onClick={() => setConfirmDeletePopup(false)}
              />
            </div>
            <h3 className="modal-title">Supprimer</h3>
          </div>

          <div className="p-3">
          Êtes-vous sûr de vouloir supprimer le(s) ticket(s) sélectionné(s) ?
          </div>

          <div className="p-3">
            <Button
              customClass={"basic_button btn-order"}
              label="Confirmer la suppression"
              type={"button"}
              onClick={onDelete}
            />
          </div>
        </TrueModal>
    </Layout>
  );
}

export default Tickets;
