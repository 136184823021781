export const expiresSoon = (date) => {
  const diffTime = Math.abs(new Date(date) - Date.now());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const expired = (date) => {
  return new Date(date) < Date.now();
};

export const licenceExpired = (date) => {
  const diffTime = Math.abs(new Date(date) -  Date.now());
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  return diffHours
}

export const isRenewable = (dataScanisette) => {
  return dataScanisette.filter(d => expiresSoon(d.expires) < 30 || expired(d.expires)).length > 0
}

// Return the date now in the format YYYY-MM-DD
export const dateNow = () => {
  let yourDate = new Date();
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - (offset*60*1000));
  return yourDate.toISOString().split('T')[0];
}

// Return the state of the scanisette depending of the heartbit_date provided: "active", "inactive", "inactive depuis peu" of a scanisette
export const state = (heartbit_date) => {
  if (heartbit_date === undefined) {
    return "inactive";
  }
  const myDate = new Date(heartbit_date);
  const result = myDate.getTime() // Transform myDate into millisecond
  const oneHour = 1 * 60 * 60 * 1000 // Transform 1 hour into millisecond
  const twelveHour = 12 * 60 * 60 * 1000 // Transform 12 hours into millisecond
  if (Date.now() - result < oneHour ) {
    return "active";
  } else if ( Date.now() - result < twelveHour) {
    return "inactive depuis peu";
  } else {
    return "inactive";
  }
}

/**
 * 
 * @param {Array} allowedRoles Array of roles string
 * @param {String} userRole User role
 * @returns {Boolean} Return boolean if userRoles exist in allowedRoles Array
 */
 export const isAuthorised = (allowedRoles, userRole) => (allowedRoles.findIndex((r) => (r === userRole)) !== -1)

 export const licenceScanisette = (date) => {
  if (!date) {
    return "Pas de souscription active"
  } else if (expired(date)) {
    return "Souscription expirée"
  } else if (expiresSoon(date) < 30) {
    return "Souscription bientôt expirée"
  } else {
    return "Souscription active"
  }
 }

 export function checkForMultipleAntivirusDetection(data) {
  let detectionCount = 0;

  for (const scan of data) {
    if (scan.virus.length > 0) {
      detectionCount++;
    }
  }
  return detectionCount
}