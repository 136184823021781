import React, { useContext } from "react";
import { useEffect } from "react";
import { createContext, useState } from "react";
import useInterval from "../hooks/useInterval";
import { AuthContext } from "./AuthContext";
import { getScanisetteByCustomerId } from "../services/Scanisette.services";
import { licenceExpired, state } from "../utils/func";
import {
  addAlertToCustomer,
  getAlertsByCustomerId,
} from "../services/Alert.service";
import { getAllAV } from "../services/Antivirus.services";
import { formatDate } from "../services/Utils.services";

export const AlertContext = createContext(null);

const whichType = (antivirus) => {
  if (antivirus.antivirus.name === "Eset") {
    return 0;
  } else if (antivirus.antivirus.name === "ClamAV") {
    return 2;
  } else if (antivirus.antivirus.name === "WithSecure") {
    return 3;
  }
};

const whichAV = (type) => {
  if (type === 0) {
    return "Eset";
  } else if (type === 2) {
    return "ClamAV";
  } else if (type === 3) {
    return "WithSecure";
  }
};

function AlertContextProvider({ children }) {
  const [numberAlertsInactive, setNumberAlertsInactive] = useState(null);
  const [numberAlertsOutdated, setNumberAlertsOutdated] = useState(null);
  const [dataInactive, setDataInactive] = useState([]);
  const [dataOutdated, setDataOutdated] = useState([]);
  const [dataAV, setDataAV] = useState([]);
  const { user } = useContext(AuthContext);

  const customerId = user?.customers[0]?.id;

  useEffect(() => {
    getAllAV()
      .then((resp) => {
        setDataAV([...resp.data])
      })
      .catch((e) => console.error("Error while retrieving the antivirus", e));
  }, []);

  useInterval(() => {
    if (customerId) {
      getScanisetteByCustomerId(customerId)
        .then((resp) => {
          resp.data.forEach((d) => {
            if (state(d?.heartbit_date) === "inactive") {
              addAlertToCustomer({
                scanisette_id: d.id,
                customer_id: customerId,
                type: 1, // type or id = 1 means the scanisette is inactive since more than 12h
              }).catch((e) =>
                console.error(
                  "Error while creating an alert about an inactive scanisette",
                  e
                )
              )
            }

            d.antivirus.forEach((av) => {
              if (licenceExpired(av.date_check_version) > 24) {
                addAlertToCustomer({
                  scanisette_id: d.id,
                  customer_id: customerId,
                  type: whichType(av),
                }).catch((e) =>
                  console.error(
                    "Error while creating an alert about an outdated antivirus",
                    e
                  )
                )
              }
            });
          });
        })
        .catch((e) =>
          console.error(
            "Error while retrieving the scanisette by customer ID",
            e
          )
        );
    }
  }, 1000 * 60 * 5);

  useInterval(() => {
    if (customerId) {
      getAlertsByCustomerId(customerId)
        .then((resp) => {
          setNumberAlertsInactive(resp.data.filter((d) => d.type === 1 && !d.isRead).length); // type or id = 1 means scanisette is inactive since more than 12h
          setNumberAlertsOutdated(resp.data.filter((d) => d.type !== 1 && !d.isRead).length);
        })
        .catch((e) =>
          console.error("Error while retrieving alerts by customer ID", e)
        );
    }
  }, 1000 * 60 * 5);

  const getDatas = () => {
    getAlertsByCustomerId(customerId)
      .then((resp) => {
        let scanisettesInactive = [];
        resp.data.forEach((s) => {
          if (s.type === 1) {
            scanisettesInactive.push({
              id: s.scanisette.id,
              alertId: s.id,
              type: s.type,
              isRead: s.isRead,
              state: state(s?.scanisette?.heartbit_date),
              name: s?.scanisette?.custom_name,
              area: s?.scanisette?.place_group?.name,
              hostname: s?.scanisette?.hostname,
              message: `La scanisette "${s?.scanisette?.custom_name}" est inactive depuis plus de 12h. Dernière fois active le ${formatDate(
                new Date(s?.scanisette?.heartbit_date),
                "yyyy-mm-dd"
              )} à ${formatDate(
                new Date(s?.scanisette?.heartbit_date),
                "HH:MM:SS"
              )}`,
            });
          }
        });
        setDataInactive(scanisettesInactive);

        let avALert = [];
        resp.data.forEach((d) => {
          d.scanisette.antivirus.forEach((av) => {
            if (d.type !== 1) { 
              if (whichAV(d.type) === dataAV.filter((a) => av.antivirus.id == a.id)[0]?.name && licenceExpired(av?.date_check_version) > 24)
                avALert.push({
                  id: d?.scanisette?.id,
                  alertId: d.id,
                  type: d.type,
                  isRead: d.isRead,
                  state: state(d?.scanisette?.heartbit_date),
                  name: d?.scanisette?.custom_name,
                  area: d?.scanisette?.place_group?.name,
                  hostname: d?.scanisette?.hostname,
                  message: `La base virale de l'antivirus ${whichAV(
                    d.type
                  )} a plus de 24h. Dernière mise à jour le ${formatDate(
                    new Date(av?.date_check_version),
                    "yyyy-mm-dd"
                  )} à ${formatDate(
                    new Date(av?.date_check_version),
                    "HH:MM:SS"
                  )}`,
                });
            }
          });
        });
        setDataOutdated(avALert);
      })
      .catch((e) =>
        console.error("Error while retrieving the alerts by customer ID", e)
      );
  };
  
  useEffect(() => {
    if (customerId && dataAV.length > 0) {
      getDatas();
    }
  }, [customerId, dataAV]);

  return (
    <AlertContext.Provider
      value={{
        numberAlertsInactive,
        setNumberAlertsInactive,
        numberAlertsOutdated,
        setNumberAlertsOutdated,
        dataInactive,
        setDataInactive,
        dataOutdated,
        setDataOutdated, 
        getDatas
      }}
    >
      {children}
    </AlertContext.Provider>
  );
}

export default AlertContextProvider;
