import React from "react";
import { expired, expiresSoon, licenceScanisette } from "../utils/func";
// import Button from "./Button.generic";

function FlatCardScan2({ id, icon, label, data, customClass, onClick }) {
  return (
    <div id={id} className={`flatCard_${customClass}`}>
      <div className="card_icon">
        <img src={icon} alt="Scanisette icon" />
      </div>
      <div className="card_info">
        <span
          className={
            data.state === "active"
              ? "active"
              : data.state === "inactive"
              ? "inactive"
              : "almost-inactive"
          }
        >
          {label}
        </span>
        <br />
        {licenceScanisette(data.expires)
          }
      </div>
      <div className="card-column">
        <span className="bold">{data.name}</span>
        <span>{data.area ? data.area : '"Lieu à définir"'}</span>
        
      </div>
      <div className="card-column">
        <span className="blue bold">{data.scans}</span>
        <span>scans</span>
      </div>
      <div className="card-column">
        <span className="blue bold">{data.virus}</span>
        <span>virus détectés</span>
      </div>
      {/* <Button
        customClass={
          expired(data.expires) || expiresSoon(data.expires) < 30
            ? "basic_button btn-order"
            : "basic_button btn-order disabled"
        }
        label="Renouveler"
        type={"button"}
        onClick={onClick}
        disabled={data.state === "active" ? true : false}
      /> */}
    </div>
  );
}

export default FlatCardScan2;
