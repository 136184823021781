import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import TabNavItem from "../components/TabNavItem.generic";
import TabContent from "../components/TabContent.generic";
import Table from "../components/Table.generic";
import AlertsTableRow from "./Table/AlertsTableRow";
import Pagination from "../components/Pagination.generic";
import { AlertContext } from "../contexts/AlertContext";

const tableHeader = ["Etat", "Nom", "Hostname", "Lieu", "Message", ""];

function Alerts() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || "tab1");
  const [currentPageInactive, setCurrentPageInactive] = useState(1);
  const [currentPageOutdated, setCurrentPageOutdated] = useState(1);
  const { dataInactive, dataOutdated, getDatas } = useContext(AlertContext);

  let PageSize = +12;

  useEffect(() => {
    setActiveTab(searchParams.get('tab') || "tab1")
  }, [searchParams])

  const currentDataInactive = useMemo(() => {
    const firstPageIndex = (currentPageInactive - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(dataInactive.length / PageSize);
    if (totalPageCount < currentPageInactive) {
      setCurrentPageInactive(1);
    }
    return dataInactive.slice(firstPageIndex, lastPageIndex);
  }, [dataInactive, currentPageInactive, PageSize]);

  const currentDataOutdated = useMemo(() => {
    const firstPageIndex = (currentPageOutdated - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(dataOutdated.length / PageSize);
    if (totalPageCount < currentPageOutdated) {
      setCurrentPageOutdated(1);
    }
    return dataOutdated.slice(firstPageIndex, lastPageIndex);
  }, [dataOutdated, currentPageOutdated, PageSize]);

  return (
    <Layout>
      <h1 className="title">ALERTES</h1>
      <ul className="nav-items">
        <TabNavItem
          title="Scanisette Inactive"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Base virale expirée"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
          <Table
            theadData={tableHeader}
            tbodyData={currentDataInactive}
            customClass={"table-scanisettes"}
            TableRow={AlertsTableRow}
            getDatas={getDatas}
          />
          <Pagination
            currentPage={currentPageInactive}
            totalCount={dataInactive.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPageInactive(page)}
          />
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          <Table
            theadData={tableHeader}
            tbodyData={currentDataOutdated}
            customClass={"table-scanisettes"}
            TableRow={AlertsTableRow}
            getDatas={getDatas}
          />
          <Pagination
            currentPage={currentPageOutdated}
            totalCount={dataOutdated.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPageOutdated(page)}
          />
        </TabContent>
      </div>
    </Layout>
  );
}

export default Alerts;
