import React from "react";
import AuthContextProvider from "./contexts/AuthContext";
import Routing from "./routes/index.routes";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import AlertContextProvider from "./contexts/AlertContext";

function App() {
  return (
    <>
      <AuthContextProvider>
        <AlertContextProvider>
          <Routing />
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            newestOnTop
            closeOnClick
            rtl={false}
          />
        </AlertContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
