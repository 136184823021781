export const setCookie = (name, value) => {
  document.cookie = `${name}=${value}`;
}

export const getCookie = (name) => {
  let cookieFind = ""
  document.cookie.split(";").forEach((c) => {
      if(c?.split("=")[0].trim() === name) {
          cookieFind = c?.split("=")[1]
      }
  })
  return cookieFind
}

export const deleteCookie = (name) => {
  if(getCookie(name)) {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export const formatDate = (date, format = "dd-mm-yyyy") => {
  switch(format) {
      case "dd-mm-yyyy":
          return `${date.getDate() < 10 ? "0"+date.getDate() : date.getDate()}-${date.getMonth() + 1 < 10 ? "0"+(date.getMonth() + 1) : date.getMonth() + 1}-${date.getFullYear()}`
      case "yyyy-mm-dd":
          return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0"+(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? "0"+date.getDate() : date.getDate()}`  
      case "HH:MM:SS": 
          return `${date.getHours()}:${date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? "0"+date.getSeconds() : date.getSeconds()}`
      case "yyyy-mm-dd HH:MM:SS": 
          return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0"+(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? "0"+date.getDate() : date.getDate()} ${date.getHours()}:${date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? "0"+date.getSeconds() : date.getSeconds()}`
      default:
          return ""
  }
}

export function calculateAverageFileCount(data) {
  let totalFiles = 0;
  const totalEntries = data.length;

  data.forEach((entry) => {
    totalFiles += entry.nb_files;
  });

  const averageFiles = totalFiles / totalEntries;
  return averageFiles;
}

export function calculateAverageVirusCount(data) {
  let totalFiles = 0;
  const totalEntries = data.length;

  data.forEach((entry) => {
    totalFiles += entry.nb_virus;
  });

  const averageFiles = totalFiles / totalEntries;
  return averageFiles;
}

export const assignValueToEntity = (value, property, entity) => {
  let entries = Object.entries(entity)
  entries[entries.findIndex((e) => e[0] == property)][1] = value
  return Object.fromEntries(entries)
}

