import React, { useState } from "react";
import InputWithLabel from "../components/InputWithLabel.generic";
import Layout from "../layouts/Layout";
import { useDropzone } from "react-dropzone";
import Button from "../components/Button.generic";
import { createCustomer } from "../services/Customer.services";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "../services/Upload.services";

function CreateCustomer() {
  const [customer, setCustomer] = useState({
    customerName: "",
    siret: "",
    address: "",
    zipCode: "",
    city: "",
    lastname: "",
    firstname: "",
    phone: "",
    email: "",
    anniversary: new Date().toISOString().split("T")[0],
  });
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="thumbInner">
        <img
          src={file.preview}
          className="thumbInner-img"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const handleSave = () => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("file", files[0]);
      uploadFile(formData)
        .then((resp) =>
          createCustomer({
            name: customer.customerName,
            siret: customer.siret,
            address: customer.address,
            postal_code: customer.zipCode,
            city: customer.city,
            phone_number: customer.phone,
            mail: customer.email,
            birth_date: customer.anniversary,
            logo: `${process.env.REACT_APP_API_URL}/${resp.data.name}`,
          })
            .then(() => {
              navigate("/customers");
            })
            .catch((e) => console.error("Error while creating a customer", e))
        )
        .catch((e) => console.error("Error while uploading a logo", e));
    } else {
      createCustomer({
        name: customer.customerName,
        siret: customer.siret,
        address: customer.address,
        postal_code: customer.zipCode,
        city: customer.city,
        phone_number: customer.phone,
        mail: customer.email,
        birth_date: customer.anniversary,
      })
        .then(() => {
          navigate("/customers");
        })
        .catch((e) => console.error("Error while creating a customer", e));
    }
  };

  return (
    <Layout>
      <h1 className="title">Créer un client</h1>
      <div className="max-width-100">
        <p className="bold mt-8">Client</p>
        <div className="row">
          <div className="card-column">
            <InputWithLabel
              type="text"
              label="Nom société"
              value={customer?.customerName}
              placeholder="Nom société"
              customClass="input-customer"
              customClassLabel="input_label mt-3"
              onChange={(e) =>
                setCustomer({ ...customer, customerName: e.target.value })
              }
              id="customerName"
            />
          </div>
          <div className="card-column">
            <InputWithLabel
              type="text"
              label="N° SIRET"
              value={customer?.siret}
              placeholder="012325567649"
              customClass="input-customer"
              customClassLabel="input_label mt-3"
              onChange={(e) =>
                setCustomer({ ...customer, siret: e.target.value })
              }
              id="siret"
            />
          </div>
        </div>

        <div className="card-column">
          <InputWithLabel
            type="text"
            label="Adresse"
            value={customer?.address}
            placeholder="8 rue Edmond Rostand"
            customClass="input-customer"
            customClassLabel="input_label mt-8"
            onChange={(e) =>
              setCustomer({ ...customer, address: e.target.value })
            }
            id="address"
          />
        </div>

        <div className="row">
          <div className="card-column">
            <InputWithLabel
              type="text"
              label="Code postal"
              value={customer?.zipCode}
              placeholder="31200"
              customClass="input-customer"
              customClassLabel="input_label mt-8"
              onChange={(e) =>
                setCustomer({ ...customer, zipCode: e.target.value })
              }
              id="zipCode"
            />
          </div>
          <div className="card-column">
            <InputWithLabel
              type="text"
              label="Ville"
              value={customer?.city}
              placeholder="Toulouse"
              customClass="input-customer"
              customClassLabel="input_label mt-8"
              onChange={(e) =>
                setCustomer({ ...customer, city: e.target.value })
              }
              id="city"
            />
          </div>
        </div>

        <p className="bold mt-12">Contact</p>
        {/* <div className="row">
          <div className="card-column">
            <InputWithLabel
              type="text"
              label="Nom"
              value={customer?.lastname}
              placeholder="Nom"
              customClass="input-customer"
              customClassLabel="input_label mt-3"
              onChange={(e) =>
                setCustomer({ ...customer, lastname: e.target.value })
              }
              id="lastname"
            />
          </div>
          <div className="card-column">
            <InputWithLabel
              type="text"
              label="Prénom"
              value={customer?.firstname}
              placeholder="Prénom"
              customClass="input-customer"
              customClassLabel="input_label mt-3"
              onChange={(e) =>
                setCustomer({ ...customer, firstname: e.target.value })
              }
              id="firstname"
            />
          </div>
        </div> */}

        <div className="row">
          <div className="card-column">
            <InputWithLabel
              type="text"
              label="Téléphone"
              value={customer?.phone}
              placeholder="0605040302"
              customClass="input-customer"
              customClassLabel="input_label mt-3"
              onChange={(e) =>
                setCustomer({ ...customer, phone: e.target.value })
              }
              id="phone"
            />
          </div>
          <div className="card-column">
            <InputWithLabel
              type="email"
              label="E-mail"
              value={customer?.email}
              placeholder="E-mail"
              customClass="input-customer"
              customClassLabel="input_label mt-3"
              onChange={(e) =>
                setCustomer({ ...customer, email: e.target.value })
              }
              id="email"
            />
          </div>
        </div>

        <p className="bold mt-12">Date anniversaire</p>
        <div className="card-column">
          <InputWithLabel
            type="date"
            label="Date d'ouverture du contrat"
            value={customer?.anniversary}
            customClass="input-customer"
            customClassLabel="input_label mt-3"
            onChange={(e) =>
              setCustomer({ ...customer, anniversary: e.target.value })
            }
            id="anniversary"
          />
        </div>

        <p className="bold mt-12">Logo</p>
        <div className="container">
          <div {...getRootProps({ className: "dropzone-customer" })}>
            <input {...getInputProps()} />
            <img src="/assets/image.svg" alt="Icône image" className="mt-3" />
            <p className="bold">Glisser-déposer un logo</p>
            <p>(dimension préconisée 200x75 px)</p>
            <p className="edit" onClick={open}>
              Parcourir
            </p>
          </div>
          <div className="thumbsContainer">{thumbs}</div>
        </div>
        <div className="row-btn">
          <Button
            label="Enregistrer"
            customClass="basic_button btn-order mt-8 mb-12"
            type="button"
            onClick={handleSave}
          />
          <Button
            label="Annuler"
            customClass="basic_button btn-order mt-8 mb-12"
            type="button"
            onClick={() => navigate("/customers")}
          />
        </div>
      </div>
    </Layout>
  );
}

export default CreateCustomer;
