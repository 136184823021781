import React, { useContext } from "react";
import Button from "../../components/Button.generic";
import { markAsRead } from "../../services/Alert.service";
import { AlertContext } from "../../contexts/AlertContext";

function AlertsTableRow({ data, getDatas }) {
  const { setNumberAlertsOutdated, setNumberAlertsInactive } =
    useContext(AlertContext);

  const handleMarkAsRead = () => {
    markAsRead(data.alertId)
      .then(() => {
        if (data.type === 1) {
          setNumberAlertsInactive((value) => value - 1);
        } else {
          setNumberAlertsOutdated((value) => value - 1);
        }
        getDatas();
      })
      .catch((e) => console.error("Error while marking as read an alert", e));
  };

  return (
    <tr id={data.id} className={data.isRead ? "opacity" : ""}>
      <td>
        <span
          className={`span-status ${
            data.state === "active"
              ? "active"
              : data.state === "inactive"
              ? "inactive"
              : "almost-inactive"
          }`}
        >
          {data.state === "active"
            ? "Active"
            : data.state === "inactive"
            ? "Inactive"
            : "Inactive depuis peu"}
        </span>
      </td>
      <td>{data.name}</td>
      <td>{data.hostname}</td>
      <td>{data.area ? data.area : '"Lieu à définir"'}</td>
      <td className="error pt-2 pr-3 pb-2 pl-3">{data.message}</td>
      <td>
        {!data.isRead ? (
          <Button
            label={"Marquer comme lue"}
            customClass="basic_button btn-order"
            onClick={handleMarkAsRead}
          />
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
}

export default AlertsTableRow;
