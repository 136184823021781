import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FlatCardAV4 from "../components/FlatCardAV4.generic";
import FlatCardAV5 from "../components/FlatCardAV5.generic";
import TabContent from "../components/TabContent.generic";
import TabNavItem from "../components/TabNavItem.generic";
import Layout from "../layouts/Layout";
import { addAntivirus, getAllAV, getStatsAV, updateAVById } from "../services/Antivirus.services";
import { assignValueToEntity } from "../services/Utils.services";
import InputWithLabel from "../components/InputWithLabel.generic";
import Modal4 from "../components/Modal4.generic";
import { uploadFile } from "../services/Upload.services";
import TrueCard from "../components/TrueCard.generic";
import { v4 } from "uuid";
import Switch from "../components/Switch.generic";
import ElementMaker from "../components/ElementMaker.generic";
import Button from "../components/Button.generic";

function AntiVirus() {
  const [activeTab, setActiveTab] = useState("tab2");
  const [dataAV, setDataAV] = useState([]);
  const [statsAV, setStatsAV] = useState([]);
  const [isModalShow, setIsModalShow] = useState(false)
  const [newAV, setNewAV] = useState(null)
  const [file, setFile] = useState(null)
  const navigate = useNavigate();
  const baseAV = {
    logo: "https://via.placeholder.com/150x50",
    premium: false,
    description: "",
    name: "",
    folder: "",
    executable_cmd: "",
    version_cmd: "",
    no_virus: "",
    virus: "",
    nb_files: "",
    date_string: "",
    file_for_date: "",
    temp_folder: "",
    activated: true,

  }
  const [isActiveArray, setIsActiveArray] = useState([]);
  const [showInputEleArray, setShowInputEle] = useState([]);

  useEffect(() => {
    setNewAV(baseAV)
    getStatsAV()
      .then((resp) => setStatsAV(resp.data))
      .catch((e) =>
        console.error("Error while retrieving the stats about the antivirus", e)
      );
  }, []);

  const loadData = () => {
    getAllAV()
    .then((resp) => {
      const antiVirus = resp.data.map((av) => {
        return {
          id: av?.id,
          icon: av?.logo || "https://via.placeholder.com/150x50",
          nb_customer: statsAV.filter((s) => s.id === av.id)[0]?.customer,
          nb_licence: statsAV.filter((s) => s.id === av.id)[0]?.nb_licence,
          paid: av?.premium,
          content: av?.description,
          name: av?.name,
          folder: av?.folder,
          executable_cmd: av?.executable_cmd,
          version_cmd: av?.version_cmd,
          no_virus: av?.no_virus,
          virus: av?.virus,
          nb_files: av?.nb_files,
          nb_virus: av?.nb_virus,
          date_string: av?.date_string,
          file_for_date: av?.file_for_date,
          temp_folder: av?.temp_folder
        };
      });
      setDataAV(antiVirus);
    })
    .catch((e) => console.error("Error while retrieving the antivirus", e));
  }

  useEffect(() => {
    loadData()
  }, [statsAV]);

  const handleClick = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  const handleAdd = async () => {
    if(file) {
      let fm = new FormData()
      fm.append("file", file)
      let resp = await uploadFile(fm)
      let c = assignValueToEntity(`${process.env.REACT_APP_API_URL}${resp.data.name}`, "logo", newAV)
      addAntivirus(c).then((resp) => {
        setIsModalShow(false)
        loadData()
      }).catch((e) =>
        console.error("Error while adding the antivirus", e)
      )
    } else {
      addAntivirus(newAV).then((resp) => {
        setIsModalShow(false)
        loadData()
      }).catch((e) =>
        console.error("Error while adding the antivirus", e)
      )
    }
  }

  return (
    <Layout>
      <h1 className="title">MES ANTIVIRUS</h1>
      <ul className="nav-items">
        {/* <TabNavItem
          title="Licences"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        /> */}
        <TabNavItem
          title="Informations"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        {/* <TabContent id="tab1" activeTab={activeTab}>
          <div className="flex-column">
            {dataAV.map((d) => (
              <FlatCardAV4
                key={d.id}
                data={d}
                customClass="grid-5 antivirus-flatcardav4"
                customClassAccordion="grid-4-flatcardav4-accordion"
                onClick={(e) => handleClick(e.currentTarget.id)}
              />
            ))}
          </div>
        </TabContent> */}
        <TabContent id="tab2" activeTab={activeTab}>
          <div className="d-flex column align-items-center g-3">
            {dataAV.map((d) => (
              <FlatCardAV5
                key={d.id}
                data={d}
                customClass="grid-6-content"
                paid={d.paid}
                setData={setDataAV}
                refresh={() => loadData()}
              />
            ))}
            <button
              className="basic_button button_custom"
              onClick={() => setIsModalShow(true)}>Ajouter un antivirus</button>
          </div>
        </TabContent>
      </div>
      <Modal4 title={`Ajout d'un antivirus`} show={isModalShow} onClose={() => setIsModalShow(false)} >
        <div className="flex-column g-2">
          <InputWithLabel
            type="text"
            label="Name"
            value={newAV?.name}
            placeholder="Nom antivirus"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "name", newAV))}
            id="customerName"/>
          <label htmlFor="content_av" className="input_label mt-3 w85">Description</label>
          <textarea
            rows={5}
            value={newAV?.description}
            placeholder="Description"
            className="basic_input w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "description", newAV))}
            id="content_av"/>
          <InputWithLabel
            type="file"
            label="Logo"
            placeholder="Logo"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setFile(e.target.files[0])}
            id="customerName"/>
          <InputWithLabel
            type="text"
            label="Dossier antivirus"
            value={newAV?.folder}
            placeholder="Path to folder"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "folder", newAV))}
            id="folder"/>
          <InputWithLabel
            type="text"
            label="Regex for date"
            value={newAV?.date_string}
            placeholder="Regex"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "date_string", newAV))}
            id="date_string"/>
          <InputWithLabel
            type="text"
            label="File for date"
            value={newAV?.file_for_date}
            placeholder="path to file"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "file_for_date", newAV))}
            id="file_for_date"/>
          <InputWithLabel
            type="text"
            label="Regex for files number"
            value={newAV?.nb_files}
            placeholder="Regex file number"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "nb_files", newAV))}
            id="nb_files"/>
          <InputWithLabel
            type="text"
            label="Regex for virus number"
            value={newAV?.nb_virus}
            placeholder="Regex virus number"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "nb_virus", newAV))}
            id="nb_virus"/>
          <InputWithLabel
            type="text"
            label="Regex for no virus"
            value={newAV?.no_virus}
            placeholder="Regex for no virus"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "no_virus", newAV))}
            id="no_virus"/>
          <InputWithLabel
            type="text"
            label="Executable for version"
            value={newAV?.version_cmd}
            placeholder="Path to exe"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "version_cmd", newAV))}
            id="version_cmd"/>
          <InputWithLabel
            type="text"
            label="Executable for scan"
            value={newAV?.executable_cmd}
            placeholder="Path to exe"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "executable_cmd", newAV))}
            id="executable_cmd"/>
          <InputWithLabel
            type="text"
            label="Path to tmp folder"
            value={newAV?.temp_folder}
            placeholder="Path to tmp folder"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "temp_folder", newAV))}
            id="temp_folder"/>
          <InputWithLabel
            type="text"
            label="Regex for virus found"
            value={newAV?.virus}
            placeholder="Regex for virus found"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setNewAV(assignValueToEntity(e.target.value, "virus", newAV))}
            id="virus"/>
          <Button
            label={"Enregistrer"}
            customClass="basic_button btn-order"
            onClick={() => handleAdd()}/>
        </div>
      </Modal4>
    </Layout>
  );
}

export default AntiVirus;
