import React from "react";
import { useNavigate } from "react-router-dom";

function CustomerTableRow({ data }) {
  const navigate = useNavigate();

  const handleClick = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  return (
    <tr id={data.id} onClick={(e) => handleClick(e.currentTarget.id)} className="pointer">
      <td>{data.name}</td>
      <td>{data.nb_scanisette}</td>
      <td>{data?.expires?.split("T")[0]}</td>
      <td>{data.nb_scan}</td>
      <td>{data.nb_scan_month}</td>
      <td>{data.nb_virus}</td>
      <td>{data.city}</td>
      <td>
        <img src="/assets/arrow.svg" alt="Arrow icon" />
      </td>
    </tr>
  );
}

export default CustomerTableRow;
