import React, { useEffect, useState } from "react";
import { getDeployedScanVirus } from "../services/Stats.services";

function WelcomeSection() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getDeployedScanVirus()
      .then((resp) => {
        const dataScanisette = {
          scans: resp?.data?.scan,
          virus: resp?.data?.virus,
          scanisettes: resp?.data?.deployed,
        };
        setData(dataScanisette);
      })
      .catch((e) =>
        console.error(
          "Error while retrieving the deployed scnanisette, the number of virus detected and the number of scans realized",
          e
        )
      );
  }, []);

  return (
    <div className="data-section">
      <div className="card">
        <img src="/assets/scanisette2.svg" alt="Icon usb key" />
        <span>
          <strong>{data?.scanisettes}</strong>
        </span>
        <span>scanisettes déployées</span>
      </div>

      <div className="card">
        <img src="/assets/scan.svg" alt="Icon scan" />
        <span>
          <strong>{data?.scans}</strong>
        </span>
        <span>scans réalisés</span>
      </div>

      <div className="card">
        <img src="/assets/virus.svg" alt="Icon virus" />
        <span>
          <strong>{data?.virus}</strong>
        </span>
        <span>virus détectés</span>
      </div>
    </div>
  );
}

export default WelcomeSection;
