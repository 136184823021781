import React, { useState } from "react";
import { deleteAntivirusById, updateAVById } from "../services/Antivirus.services";
import Button from "./Button.generic";
import ElementMaker from "./ElementMaker.generic";
import Switch from "./Switch.generic";
import Modal4 from './Modal4.generic'
import Input from "./Input.generic";
import InputWithLabel from "./InputWithLabel.generic";
import { assignValueToEntity } from '../services/Utils.services'
import { uploadFile } from '../services/Upload.services'

function FlatCardAV5({ data, customClass, paid, refresh }) {
  const [isActive, setIsActive] = useState(false);
  const [checked, setChecked] = useState(paid);
  const [showInputEle, setShowInputEle] = useState(false);
  const [content, setContent] = useState(data.content);
  const [isModalShow, setIsModalShow] = useState(false)
  const [clone, setClone] = useState(null)
  const [file, setFile] = useState(null)

  const handleSave = () => {
    setShowInputEle(!showInputEle);
    if (showInputEle) {
      updateAVById(data.id, { description: content }).catch((e) =>
        console.error("Error while updating the antivirus", e)
      );
    }
  };

  const handleToggle = () => {
    setChecked(!checked);
    updateAVById(data.id, { premium: !checked }).catch((e) =>
      console.error("Error while updating the antivirus", e)
    );
  };

  const handleEdit = () => {
    setClone(JSON.parse(JSON.stringify(data)))
    setIsModalShow(true)
  }

  const handleDelete = () => {
    if(window.confirm("Êtes-vous sûr de vouloir supprimer cet antivirus ?")) {
      deleteAntivirusById(data.id).then((resp) => {
        refresh()
      }).catch((e) =>
        console.error("Error while deleting the antivirus", e)
      )
    }
  }

  const handleUpdate = async () => {
    let cl = JSON.parse(JSON.stringify(clone))
    cl.logo = cl.icon
    cl.description = cl.content
    if(file) {
      let fm = new FormData()
      fm.append("file", file)
      let resp = await uploadFile(fm)
      let c = assignValueToEntity(`${process.env.REACT_APP_API_URL}${resp.data.name}`, "logo", cl)
      updateAVById(data.id, c).then((resp) => {
        setIsModalShow(false)
        refresh()
      }).catch((e) =>
        console.error("Error while updating the antivirus", e)
      )
    } else {
      updateAVById(data.id, cl).then((resp) => {
        setIsModalShow(false)
        refresh()
      }).catch((e) =>
        console.error("Error while updating the antivirus", e)
      )
    }
  }

  return (
    <>
      <div id={data.id} className={`flatCard ${customClass}`}>
        <div className="card_icon">
          <img src={data.icon} alt="Antivirus logo" />
        </div>
        <p className="truncated">{data.content}</p>
        <div className="container-switch">
          <Switch
            isOn={checked}
            onColor="#5f8bfa"
            handleToggle={handleToggle}
            id={`${data.name}-${data.id}`}
          />
          {checked ? "Antivirus payant" : "Antivirus gratuit"}
        </div>
        <img
          src={isActive ? "/assets/arrow-up.svg" : "/assets/arrow-down.svg"}
          alt="Up or Down arrow"
          onClick={() => setIsActive(!isActive)}
        />
        <Button
          label={"Editer"}
          customClass="basic_button btn-order"
          onClick={() => handleEdit()}/>
        <Button
          label={"Supprimer"}
          customClass="basic_button btn-order b-error"
          onClick={() => handleDelete()}/>
      </div>
      {isActive && (
        <div className="av-content">
          <h3>{data.name}</h3>
          <p>
            <ElementMaker
              customClass={"input-content"}
              type={"textarea"}
              value={content}
              handleChange={(e) => setContent(e.target.value)}
              showInputEle={showInputEle}
              customClassSpan={"span-content"}
            />
          </p>
          <Button
            label={showInputEle ? "Enregistrer" : "Editer"}
            customClass="basic_button btn-order"
            onClick={handleSave}
          />
        </div>
      )}
      <Modal4 title={`Édition de l'antivirus ${data.name}`} show={isModalShow} onClose={() => setIsModalShow(false)} >
        <div className="flex-column g-2">
          <InputWithLabel
            type="text"
            label="Name"
            value={clone?.name}
            placeholder="Nom antivirus"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "name", clone))}
            id="customerName"/>
          <label htmlFor="content_av" className="input_label mt-3 w85">Description</label>
          <textarea
            rows={5}
            value={clone?.content}
            placeholder="Description"
            className="basic_input w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "content", clone))}
            id="content_av"/>
          <InputWithLabel
            type="file"
            label="Logo"
            placeholder="Logo"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setFile(e.target.files[0])}
            id="customerName"/>
          <InputWithLabel
            type="text"
            label="Dossier antivirus"
            value={clone?.folder}
            placeholder="Path to folder"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "folder", clone))}
            id="folder"/>
          <InputWithLabel
            type="text"
            label="Regex for date"
            value={clone?.date_string}
            placeholder="Regex"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "date_string", clone))}
            id="date_string"/>
          <InputWithLabel
            type="text"
            label="File for date"
            value={clone?.file_for_date}
            placeholder="path to file"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "file_for_date", clone))}
            id="file_for_date"/>
          <InputWithLabel
            type="text"
            label="Regex for files number"
            value={clone?.nb_files}
            placeholder="Regex file number"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "nb_files", clone))}
            id="nb_files"/>
          <InputWithLabel
            type="text"
            label="Regex for virus number"
            value={clone?.nb_virus}
            placeholder="Regex virus number"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "nb_virus", clone))}
            id="nb_virus"/>
          <InputWithLabel
            type="text"
            label="Regex for no virus"
            value={clone?.no_virus}
            placeholder="Regex for no virus"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "no_virus", clone))}
            id="no_virus"/>
          <InputWithLabel
            type="text"
            label="Executable for version"
            value={clone?.version_cmd}
            placeholder="Path to exe"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "version_cmd", clone))}
            id="version_cmd"/>
          <InputWithLabel
            type="text"
            label="Executable for scan"
            value={clone?.executable_cmd}
            placeholder="Path to exe"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "executable_cmd", clone))}
            id="executable_cmd"/>
          <InputWithLabel
            type="text"
            label="Path to tmp folder"
            value={clone?.temp_folder}
            placeholder="Path to tmp folder"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "temp_folder", clone))}
            id="temp_folder"/>
          <InputWithLabel
            type="text"
            label="Regex for virus found"
            value={clone?.virus}
            placeholder="Regex for virus found"
            customClass="basic_input w85"
            customClassLabel="input_label mt-3 w85"
            onChange={(e) => setClone(assignValueToEntity(e.target.value, "virus", clone))}
            id="virus"/>
          <Button
            label={"Enregistrer"}
            customClass="basic_button btn-order"
            onClick={() => handleUpdate()}/>
        </div>
      </Modal4>
    </>
  );
}

export default FlatCardAV5;
