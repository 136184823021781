import React, { useContext, useMemo, useState } from "react";

import Layout from "../layouts/Layout";
import FlatCardAV2 from "../components/FlatCardAV2.generic";
import TabNavItem from "../components/TabNavItem.generic";
import TabContent from "../components/TabContent.generic";
import FlatCardScan2 from "../components/FlatCardScan2.generic";
import { licenceScanisette, state } from "../utils/func";

import { v4 } from "uuid";
import { useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { getScanisetteByCustomerId } from "../services/Scanisette.services";
import { getCustomerById } from "../services/Customer.services";
import Pagination from "../components/Pagination.generic";
import TrueCard from "../components/TrueCard.generic";

function Subscription() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [dataScanisette, setDataScanisette] = useState([]);
  const [dataAV, setDataAV] = useState([]);
  const { user } = useContext(AuthContext);
  const [currentPageScanisette, setCurrentPageScanisette] = useState(1);
  const [currentPageAV, setCurrentPageAV] = useState(1);

  let PageSize = +10;

  const customerId = user?.customers[0]?.id;

  useEffect(() => {
    if (customerId) {
      getScanisetteByCustomerId(customerId)
        .then((resp) => {
          const scanisettes = resp.data.map((s) => {
            let nb_virus = [];
            s.scans.map((s) => (nb_virus = [...nb_virus, ...s.virus]));
            return {
              id: s?.id,
              state: state(s?.heartbit_date),
              scans: s?.scans?.length,
              virus: nb_virus?.length,
              name: s?.custom_name,
              area: s?.place_group?.name,
              expires: s?.licence?.end_date, // Here the expiration date refers to the scanisette licence and not the antivirus subscription
            };
          });
          setDataScanisette(scanisettes);
        })
        .catch((e) =>
          console.error(
            "Error while retrieving the scanisette by customer ID",
            e
          )
        );
    }
    getCustomerById(customerId)
      .then((resp) => {
        const antivirus = resp.data.antivirus?.map((av) => {
          return {
            id: av?.id,
            icon: av?.antivirus?.logo,
            expires: av?.end_date,
            free: !av?.antivirus?.premium,
            name: av?.antivirus?.name,
            content: av?.antivirus?.description,
          };
        });
        setDataAV(antivirus);
      })
      .catch((e) => console.error("Error while retrieving the antivirus", e));
  }, [customerId]);

  const currentDataScanisette = useMemo(() => {
    const firstPageIndex = (currentPageScanisette - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(dataScanisette.length / PageSize);
    if (totalPageCount < currentPageScanisette) {
      setCurrentPageScanisette(1);
    }
    return dataScanisette.slice(firstPageIndex, lastPageIndex);
  }, [dataScanisette, currentPageScanisette, PageSize]);

  const currentDataAV = useMemo(() => {
    const firstPageIndex = (currentPageAV - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(dataAV.length / PageSize);
    if (totalPageCount < currentPageAV) {
      setCurrentPageAV(1);
    }
    return dataAV.slice(firstPageIndex, lastPageIndex);
  }, [dataAV, currentPageAV, PageSize]);

  return (
    <Layout>
      <h1 className="title">SOUSCRIPTION</h1>
      <ul className="nav-items">
        <TabNavItem
          title="Souscriptions Scanisette"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {/* <TabNavItem
          title="Licences Antivirus"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        /> */}
      </ul>
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
          <div className="d-flex column align-items-center g-3">
           {currentDataScanisette.map((s) => (
             <TrueCard
             key={v4()}
             classNameCardWrapper={
               "w86 hpx120 grid grid-cols-5 align-items-center g-3 p-3 large-radius border-lg b-border-white-blue box-shadow-hover box-border"
             }
           >
             <div
               className={`rounded wpx50 hpx50 justify-self-center ${
                 s.state === "active"
                   ? "b-active"
                   : s.state === "inactive"
                   ? "b-inactive"
                   : "b-almost-inactive"
               }`}
             >
               <img
                 src={"/assets/scanisette.svg"}
                 width={"80px"}
                 height={"80px"}
                 className="translate-15px"
                 alt="Scanisette icon"
               />
             </div>
             <div className="d-flex column align-items-start g-1">
               <span
                 className={`pt-1 pl-2 pb-1 pr-2 bold small-radius ${
                   s.state === "active"
                     ? "b-active"
                     : s.state === "inactive"
                     ? "b-inactive"
                     : "b-almost-inactive"
                 }`}
               >
                 {s.state === "active"
                 ? "Active"
                 : s.state === "inactive"
                 ? "Inactive"
                 : "Inactive depuis peu"}
               </span>
               
               <span>{licenceScanisette(s.expires)}</span>
             </div>
             <div className="card-column">
               <span className="bold">{s.name}</span>
               <span>{s.area ? s.area : '"Lieu à définir"'}</span>
             </div>
             <div className="card-column">
               <span className="blue bold">{s.scans}</span>
               <span>scans</span>
             </div>
             <div className="card-column">
               <span className="blue bold">{s.virus}</span>
               <span>virus détectés</span>
             </div>
           </TrueCard>
          ))}
          </div>
          <Pagination
            currentPage={currentPageScanisette}
            totalCount={dataScanisette.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPageScanisette(page)}
          />
        </TabContent>
        {/* <TabContent id="tab2" activeTab={activeTab}>
          {currentDataAV.map((av) => (
            <FlatCardAV2 key={v4()} data={av} />
          ))}
          <Pagination
            currentPage={currentPageAV}
            totalCount={dataAV.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPageAV(page)}
          />
        </TabContent> */}
      </div>
    </Layout>
  );
}

export default Subscription;
