import React, { useContext, useEffect, useMemo, useState } from "react";
import Table from "../components/Table.generic";
import { AuthContext } from "../contexts/AuthContext";
import Layout from "../layouts/Layout";
import { getScanisetteByCustomerId } from "../services/Scanisette.services";
import { state } from "../utils/func";
import ScanisettesTableRow from "./Table/ScanisettesTableRow";
import Pagination from "../components/Pagination.generic";

const tableHeader = [
  "Etat",
  "Nom",
  "Lieu",
  "Souscription antivirus",
  "Scans journalier",
  "Scans mensuel",
  "Virus détectés",
  "Date anniversaire",
  " ",
];

function Scanisettes() {
  const [data, setData] = useState([]);
  const { user } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);

  const customerId = user?.customers[0]?.id;

  let PageSize = +12;

  useEffect(() => {
    if (customerId) {
      getScanisetteByCustomerId(customerId)
        .then((resp) => {
          const scanisettes = resp.data.map((s) => {
            return {
              id: s?.id,
              state: state(s?.heartbit_date),
              scans: s?.scan_day.length === 0 ? "0" : s?.scan_day[0]?.scan,
              virus: s?.nb_viruses_total,
              name: s?.custom_name,
              area: s?.place_group?.name,
              anniversary: s?.customer?.birth_date, // Here the anniversary date refers to the scanisette licence and not the antivirus subscription
              scans_month:
                s.scan_month.length === 0 ? "0" : s?.scan_month[0]?.scan,
              licence: s?.nb_antiviruses,
            };
          });
          setData(scanisettes);
        })
        .catch((e) =>
          console.error(
            "Error while retrieving the scanisette by customer ID",
            e
          )
        );
    }
  }, [customerId]);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(data.length / PageSize);
    if (totalPageCount < currentPage) {
      setCurrentPage(1);
    }
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, PageSize]);

  return (
    <Layout>
      <h1 className="title">MES SCANISETTES</h1>
      <Table
        theadData={tableHeader}
        tbodyData={currentData}
        customClass={"table-scanisettes"}
        TableRow={ScanisettesTableRow}
      />
      <Pagination
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </Layout>
  );
}

export default Scanisettes;
