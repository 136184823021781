import axios from 'axios';

const instance = axios.create({
baseURL: "https://api.scanisette.demohc.io",
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const originalRequest = error.config;
//     if (error.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const refreshToken = localStorage.getItem('refreshToken');
//       if (refreshToken) {
//         return axios.patch(`${"https://api.scanisette.demohc.io/"}/auth/refresh_token?refresh_token=${refreshToken}`).then(resp => {
//           if (resp.status === 200) {
//             localStorage.setItem("accessToken", resp.data.accessToken);
//             localStorage.setItem("refreshToken", resp.data.refresh_token);
//             originalRequest.headers.Authorization = `Bearer ${resp.data.accessToken}`;
//             window.location.reload();
//             return axios(originalRequest);
//           }
//         }).catch(err => console.error("Error while refreshing token", err))
//       }
//     }
//     return Promise.reject(error);
//   }
// )

export default instance;
