import React from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../services/Utils.services";

function TicketTableRow({ data, selectedRow, setSelectedRow,  customerId }) {
  const navigate = useNavigate();

  const handleClick = (ticketId) => {
    navigate(`/tickets/${ticketId}`, { state: { customer: customerId}});
  };

  const handleCheckboxChange = (e) => {
    // If the selected row are not yet in the array add it, else remove it from the array
    let newArray = [...selectedRow, e.target.value];
    if (selectedRow.includes(e.target.value)) {
      newArray = newArray.filter((d) => d !== e.target.value);
    }
    setSelectedRow(newArray);
  };

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          id={data.id}
          value={data.id}
          onChange={handleCheckboxChange}
          className="pointer"
        />
      </td>
      <td className="blue">#{data.id}</td>
      <td className="bold"><div className="tooltip">{data.name}<span className="tooltiptext">{data.text}</span></div></td>
      <td>{formatDate(new Date(data.date), "yyyy-mm-dd")}</td>
      <td>{data.contact}</td>
      <td>{formatDate(new Date(data.update), "yyyy-mm-dd")}</td>
      <td>
        <span className={ "span-status tstatus-" + data.status}>
          {/* data.status === "ongoing" ? "En cours" : "Terminé" */}
          {(data.status === 1 &&
            "Nouveau")
          || (data.status === 2 && 
            "Traitement (attribué)")
          || (data.status === 3 && 
            "Traitement (prévu)")
          || (data.status === 4 && 
            "En cours")
          || (data.status === 5 && 
            "Résolu")
          || (data.status === 6 &&
            "Terminé") 
          }
        </span>
      </td>
      <td id={data.id} onClick={(e) => handleClick(e.currentTarget.id)} className="pointer">
        <img src="/assets/arrow.svg" alt="Arrow icon" />
      </td>
    </tr>
  );
}

export default TicketTableRow;
