import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../components/Card.generic";
import { v4 } from "uuid";
import { useState } from "react";
import Layout from "../layouts/Layout";
import Select from "../components/Select.generic";
import FlatCardScan from "../components/FlatCardScan.generic";
import Pagination from "../components/Pagination.generic";
import { licenceScanisette, state } from "../utils/func";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { getScanisetteByCustomerId } from "../services/Scanisette.services";
import FlatCardCustomer from "../components/FlatCardCustomer.generic";
import { getAllCustomers } from "../services/Customer.services";
import TrueCard from "../components/TrueCard.generic";

const sortByOptions = [
  {
    value: "",
    label: "Trier par",
  },
  {
    value: "name",
    label: "Nom",
  },
  {
    value: "area",
    label: "Localisation",
  },
  {
    value: "state",
    label: "Active / Inactive",
  },
  {
    value: "virus",
    label: "Virus",
  },
];

const nbrPageOptions = [
  {
    value: 12,
    label: 12,
  },
  {
    value: 24,
    label: 24,
  },
  {
    value: 48,
    label: 48,
  },
];

const sortByOptionsSAdmin = [
  {
    value: "",
    label: "Trier par",
  },
  {
    value: "name",
    label: "Nom",
  },
  {
    value: "number",
    label: "Nb de scanisette",
  },
];

function Dashboard() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [sortType, setSortType] = useState("name");
  const [sortPage, setSortPage] = useState(12);
  const [display, setDisplay] = useState("grid");
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useContext(AuthContext);
  const [role, setRole] = useState(user?.role?.weight);
  const customerId = user?.customers[0]?.id;

  let PageSize = +sortPage;

  useEffect(() => {
    if (user) {
      setRole(user?.role?.weight);
    }
  }, [user]);

  useEffect(() => {
    if (role >= 101) {
      if (customerId) {
        getScanisetteByCustomerId(customerId)
          .then((resp) => {
            const scanisettes = resp.data.map((s) => {
              let nb_virus = [];
              s.scans.map((s) => (nb_virus = [...nb_virus, ...s.virus]));
              return {
                id: s?.id,
                state: state(s?.heartbit_date),
                scans: s?.scans?.length,
                virus: nb_virus?.length,
                name: s?.custom_name,
                area: s?.place_group?.name,
                expires: s?.licence?.end_date, // Here the expiration date refers to the scanisette licence and not the antivirus subscription
                hostname: s?.hostname,
                uri_image_no_comm: s?.uri_image_no_comm,
              };
            });
            setData(scanisettes);
          })
          .catch((e) =>
            console.error(
              "Error while retrieving the scanisette by customer ID",
              e
            )
          );
      }
    } else if (role <= 100) {
      getAllCustomers()
        .then((resp) => {
          const customers = resp.data.map((c) => {
            let nb_scan = [];
            c.scanisette.map((s) => (nb_scan = [...nb_scan, ...s.scans]));
            let nb_virus = [];
            nb_scan.map((s) => (nb_virus = [...nb_virus, ...s.virus]));
            return {
              id: c?.id,
              name: c?.name,
              icon: c?.logo || "https://via.placeholder.com/200x75",
              number: c?.scanisette.length,
              scans: nb_scan?.length,
              virus: nb_virus?.length,
            };
          });
          setData(customers);
        })
        .catch((e) => console.error("Error while retrieving the customers", e));
    }
  }, [customerId, role]);

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(data.length / PageSize);
    if (totalPageCount < currentPage) {
      setCurrentPage(1);
    }
    return data
      .sort((a, b) =>
        a[sortType]?.toString().localeCompare(b[sortType]?.toString(), "fr", {
          numeric: true,
        })
      )
      .slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, PageSize, sortType]);

  const handleClick = (id) => {
    if (role >= 101) {
      navigate(`/scanisettes/${id}`);
    } else if (role <= 100) {
      navigate(`/customers/${id}`);
    }
  };

  if (role >= 101) {
    return (
      <Layout>
        <h1 className="title-dashboard">MA FLOTTE DE SCANISETTES</h1>
        <div className="display-menu">
          <Select
            name="sortBy"
            options={sortByOptions}
            handleChange={setSortType}
          />

          <div className="display-menu-right">
            <span>Scanisette par page :</span>
            <Select
              name="nbrPage"
              options={nbrPageOptions}
              handleChange={setSortPage}
            />
            <div className="display-grid-list">
              <img
                src="assets/display-grid.svg"
                alt="Grid icon"
                onClick={() => setDisplay("grid")}
                className={display === "grid" ? "active" : ""}
              />
              <img
                src="assets/display-list.svg"
                alt="List icon"
                onClick={() => setDisplay("list")}
                className={display === "list" ? "active" : ""}
              />
            </div>
          </div>
        </div>

        {display === "grid" ? (
          <>
            <div className="dashboard">
              {currentData.map((s) => (
                <Card
                  id={s.id}
                  key={v4()}
                  icon={"/assets/scanisette.svg"}
                  customClass={`scanisette ${
                    s.state === "inactive"
                      ? "inactive"
                      : s.state === "active"
                      ? "active"
                      : "almost-inactive"
                  }`}
                  onClick={(e) => handleClick(e.currentTarget.id)}
                  label={
                    s.state === "active"
                      ? "Active"
                      : s.state === "inactive"
                      ? "Inactive"
                      : "Inactive depuis peu"
                  }
                  data={s}
                />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        ) : null}

        {display === "list" ? (
          <>
            <div className="dashboard_flat g-3">
              {currentData.map((s) => (
                <TrueCard
                  key={v4()}
                  classNameCardWrapper={
                    "w86 hpx150 grid grid-cols-6 align-items-center g-3 p-3 large-radius border-lg b-border-white-blue box-shadow-hover box-border"
                  }
                  id={s.id}
                  onClick={(e) => handleClick(e.currentTarget.id)}
                >
                  <div
                    className={`rounded wpx80 hpx80 justify-self-center ${
                      s.state === "active"
                        ? "b-active"
                        : s.state === "inactive"
                        ? "b-inactive"
                        : "b-almost-inactive"
                    }`}
                  >
                    <img
                      src={"/assets/scanisette.svg"}
                      width={"110px"}
                      height={"110px"}
                      className="translate-15px"
                      alt="Scanisette icon"
                    />
                  </div>
                  <div className="d-flex column align-items-start g-1">
                    <span
                      className={`pt-1 pl-2 pb-1 pr-2 bold small-radius ${
                        s.state === "active"
                          ? "b-active"
                          : s.state === "inactive"
                          ? "b-inactive"
                          : "b-almost-inactive"
                      }`}
                    >
                      {s.state === "active"
                        ? "Active"
                        : s.state === "inactive"
                        ? "Inactive"
                        : "Inactive depuis peu"}
                    </span>

                    <span>{licenceScanisette(s.expires)}</span>
                  </div>
                  <div className="card-column">
                    <span className="bold">{s.name}</span>
                    <span className="hostname">({s.hostname})</span>
                    <span>{s.area ? s.area : '"Lieu à définir"'}</span>
                  </div>
                  <div className="card-column">
                    <span className="blue bold">{s.scans}</span>
                    <span>scans</span>
                  </div>
                  <div className="card-column">
                    <span className="blue bold">{s.virus}</span>
                    <span>virus détectés</span>
                  </div>
                  <span className="more">+</span>
                </TrueCard>
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        ) : null}
      </Layout>
    );
  } else if (role <= 100) {
    return (
      <Layout>
        <h1 className="title-dashboard">FLOTTE CLIENTS</h1>
        <div className="display-menu">
          <Select
            name="sortBy"
            options={sortByOptionsSAdmin}
            handleChange={setSortType}
          />

          <div className="display-menu-right">
            <span>Clients par page :</span>
            <Select
              name="nbrPage"
              options={nbrPageOptions}
              handleChange={setSortPage}
            />
          </div>
        </div>
        <div className="flex-column g-3">
          {currentData.map((d) => (
            <TrueCard
              key={v4()}
              classNameCardWrapper={
                "w86 hpx150 grid grid-cols-6 align-items-center g-3 p-3 large-radius border-lg b-border-white-blue box-shadow-hover box-border"
              }
              id={d.id}
              onClick={(e) => handleClick(e.currentTarget.id)}
            >
              <div>
                <img
                  src={d.icon}
                  width={"110px"}
                  height={"110px"}
                  className="object-contain"
                  alt="Customer logo"
                />
              </div>
              <span className="bold">{d.name}</span>
              <div className="card-column">
                <span className="blue bold">{d.number}</span>
                <span>scanisettes</span>
              </div>
              <div className="card-column">
                <span className="blue bold">{d.scans}</span>
                <span>scans</span>
              </div>
              <div className="card-column">
                <span className="blue bold">{d.virus}</span>
                <span>virus détectés</span>
              </div>
              <span className="more">+</span>
            </TrueCard>
          ))}
          <Pagination
            currentPage={currentPage}
            totalCount={data.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </Layout>
    );
  }
}

export default Dashboard;
