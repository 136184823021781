import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Button from "../components/Button.generic";
import InputWithLabel from "../components/InputWithLabel.generic";
import Layout from "../layouts/Layout";
import { createTicket } from "../services/Ticket.services";
import { toast } from "react-toastify";

function CreateTicket() {
  const location = useLocation ();
  const customerId = location.state.customer;
  const [title, setTitle] = useState ('');
  const [content, setContent] = useState ('');

  const navigate = useNavigate();

  const handleSave = () => {
    if((title !== "") && (content !== "")) {
      createTicket ({ title: title, content: content, status: 1, customerId: parseInt (customerId) })  
      .then(() => {
        navigate('/tickets', { state: { customer: customerId}})
        toast.success("Votre ticket a bien été créé !");
      })
      .catch((e) => console.error("Error while creating a ticket", e));
    }  
  };

  return (
    <Layout>
      <h1 className="title">
        <Link to="/tickets" state={{ customer: customerId }} >
          <img
            src="/assets/goback.svg"
            alt="Go back icon"
            className="go-back"
          />
        </Link>
        NOUVEAU TICKET
      </h1>
      <div className="flatCard max-80">
        <InputWithLabel
          type="text"
          label="Ticket Titre"
          value={title}
          placeholder="Titre"
          customClass="basic_input width-50ch"
          customClassLabel="input_label mt-3 "
          onChange={(e) => setTitle(e.target.value)}
          id="title"
        />
          <textarea className="ticket-content"
            required
            value={content}
            placeholder="Détaillez ici votre demande"
            onChange={(e) => setContent(e.target.value)}
            rows={10}
          />
        <div className="row">
          <span></span>
          <div className="flex">
            <Button
              label="&nbsp;&nbsp;&nbsp;Envoyer&nbsp;&nbsp;&nbsp;"
              customClass="btn-order"
              type="button"
              onClick={handleSave}
            />
          </div>
        </div>  
      </div>
    </Layout>
  );
}

export default CreateTicket;
