import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button.generic";
import InputWithLabel from "../components/InputWithLabel.generic";
import { resetPassword } from "../services/User.services";
import { toast } from "react-toastify";
import WelcomeSection from "../components/WelcomeSection.generic";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const sendResetEmail = (e) => {
    if (e) e.preventDefault();
    resetPassword({email: email})
      .then((resp) => {
        toast.success("Email envoyé !");
        navigate("/");
      })
      .catch((err) => {
        console.error("Error while resetting the password", err);
      });
  };

  return (
    <div className="login">
      <WelcomeSection />
      <form className="login-section" onSubmit={sendResetEmail}>
        <img
          src={"/assets/logo.svg"}
          alt="Logo scanisette"
          className="login-logo"
        />
        <h1 className="title_form mt-12 mb-6">Mot de passe oublié</h1>
        <div className="input-container">
          <InputWithLabel
            type="email"
            label="Email"
            value={email}
            placeholder="Votre email"
            customClass="basic_input input-width"
            customClassLabel="input_label mt-8"
            onChange={(e) => setEmail(e.target.value)}
            id="email"
          />
        </div>

        <Button
          label="Envoyer l'email"
          customClass="basic_button btn-order mt-12"
          type="submit"
        />
      </form>
    </div>
  );
}

export default ForgetPassword;
