import React from "react";
import { expired, expiresSoon, licenceScanisette } from "../utils/func";

function Card({ customClass, onClick, label, icon, id, data }) {
  return (
    <div id={id} onClick={onClick} className={`card_${customClass}`}>
      <div className="card_icon">
        <img src={icon} alt="Scanisette icon" />
      </div>

      <br />

      <div className="card_info">
        <div className="d-flex align-items-center justify-content-center g-1">
          <strong>{data.name}</strong>
          <span className="hostname">({data.hostname})</span>
        </div>
        <p>{data.area ? data.area : '"Lieu à définir"'}</p>
        <span
          className={
            label === "Active"
              ? "active"
              : label === "Inactive"
              ? "inactive"
              : "almost-inactive"
          }
        >
          {label}
        </span>{" "}
        
          {licenceScanisette(data.expires)}
        
        <div className="mt-1">
          <img
            src={
              data.uri_image_no_comm
                ? `${process.env.REACT_APP_API_URL}/${data.uri_image_no_comm}`
                : `${process.env.REACT_APP_API_URL}/background_scan_scanisette.png`
            }
            alt="Background image"
            className="object-contain hpx125 wpx125"
          />
        </div>
        <hr />
        <p>
          Scans: <span className="info">{data.scans}</span>
        </p>
        <p className="mb-10">
          Virus détecté: <span className="info">{data.virus}</span>
        </p>
      </div>
      <div className="container__more-details">
        <span className="more-details">+ de détails</span>
      </div>
    </div>
  );
}

export default Card;
