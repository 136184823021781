import React, { useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "../layouts/Layout";
import FlatCardAV from "../components/FlatCardAV.generic";
// import FlatCardCom from "../components/FlatCardCom.generic";
// import FlatCardGame from "../components/FlatCardGame.generic";

import { v4 } from "uuid";
import {
  checkForMultipleAntivirusDetection,
  expired,
  expiresSoon,
  licenceExpired,
  state,
} from "../utils/func";
import { useEffect } from "react";
import {
  getScanisetteById,
  updateScanisette,
} from "../services/Scanisette.services";
import { AuthContext } from "../contexts/AuthContext";
import FlatCardAV6 from "../components/FlatCardAV6.generic";
// import FlatCardCom2 from "../components/FlatCardCom2.generic";
// import FlatCardGame3 from "../components/FlatCardGame3.generic";
import { getCustomerByScanisetteId } from "../services/Customer.services";
import Button from "../components/Button.generic";
import Modal3 from "../components/Modal3.generic";
import Modal4 from "../components/Modal4.generic";
import Input from "../components/Input.generic";
import {
  createPlaceGroup,
  deletePlaceGroupById,
  getAllPlaceGroupsByCustomerId,
  updatePlaceGroupById,
} from "../services/PlaceGroup.services";
import { uploadFile } from "../services/Upload.services";
import {
  calculateAverageFileCount,
  calculateAverageVirusCount,
  formatDate,
} from "../services/Utils.services";
import Table from "../components/Table.generic";
import ScanisetteLogScanTableRow from "./Table/ScanisetteLogScanTableRow";
import Pagination from "../components/Pagination.generic";
import { getLogByScanisetteId } from "../services/Log.services";
import CardScanisette from "../components/CardScanisette.generic";
import SyncLoader from "react-spinners/SyncLoader";
import TrueModal from "../components/TrueModal.generic";
import TrueCard from "../components/TrueCard.generic";
import Switch from "../components/Switch.generic";
import {
  activateAVForScanisette,
  desactivateAVForScanisette,
} from "../services/Antivirus.services";
import { toast } from "react-toastify";
import ElementMaker from "../components/ElementMaker.generic";

// const communication = [
//   {
//     id: 1,
//     begin: "2022-11-01",
//     end: "2023-01-31",
//     name: "Nom campagne 1",
//     active: true,
//   },
//   {
//     id: 2,
//     begin: "2022-11-01",
//     end: "2023-01-31",
//     name: "Nom campagne 2",
//     active: true,
//   },
// ];

// const games = [
//   {
//     id: 1,
//     name: "Nom jeux 1",
//     description: "Lorem ipsum dolore sit",
//     active: true,
//   },
//   {
//     id: 2,
//     name: "Nom jeux 2",
//     description: "Lorem ipsum dolore sit",
//     active: true,
//   },
// ];

function Scanisette() {
  const { scanisetteId } = useParams();
  const [dataScanisette, setDataScanisette] = useState({});
  const [scanisette, setScanisette] = useState(null);
  const [dataAntiVirus, setDataAntiVirus] = useState([]);
  // const [dataCom, setDataCom] = useState([]);
  // const [dataGame, setDataGame] = useState([]);
  const { user } = useContext(AuthContext);
  const [role, setRole] = useState(user?.role?.name);
  const [customerId, setCustomerId] = useState("");
  const [manageModal, setManageModal] = useState(false);
  const [scanissetteName, setScanissetteName] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [scanisetteLocationSelect, setScanisetteLocationSelect] = useState("");
  const [scanisetteLocationInput, setScanisetteLocationInput] = useState("");
  const [imageScan, setImageScan] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [compteurPage, setCompteurPage] = useState(1);
  const navigate = useNavigate();
  const [openLogModal, setOpenLogModal] = useState(false);
  const [currentPageLog, setCurrentPageLog] = useState(1);
  const [dataLog, setDataLog] = useState([]);
  const [currentPageScan, setCurrentPageScan] = useState(1);
  const [currentDataScan, setCurrentDataScan] = useState([]);
  const [formattedScans, setFormattedScans] = useState([]);
  const [openPlaceGroupModal, setOpenPlaceGroupModal] = useState(false);
  const [placeGroupToDelete, setPlaceGroupToDelete] = useState("");
  const [showInputEle, setShowInputEle] = useState("");
  const [placeGroupLabel, setPlaceGroupLabel] = useState("");
  const [openOnlySuspicionModal, setOpenOnlySuspicionModal] = useState(false);
  const [loading, setLoading] = useState(false);

  let PageSize = +10;

  const tableHeaderLogs = ["Date", "Message"];

  useEffect(() => {
    if (user) {
      setRole(user?.role?.name);
    }
  }, [user]);

  useEffect(() => {
    if (imageScan) {
      setPreviewImage(URL.createObjectURL(imageScan));
    }
  }, [imageScan]);

  const getData = () => {
    getScanisetteById(scanisetteId)
      .then((resp) => {
        let nb_virus = [];
        setScanisette(resp.data);
        resp.data.scans.map((s) => (nb_virus = [...nb_virus, ...s.virus]));
        const scanisette = {
          id: resp?.data?.id,
          state: state(resp?.data?.heartbit_date),
          scans: resp?.data?.scans?.length,
          virus: nb_virus?.length,
          name: resp?.data?.custom_name,
          area: resp?.data?.place_group?.name,
          expires: resp?.data?.licence?.end_date,
          anniversary: resp?.data?.customer?.birth_date,
          hostname: resp?.data?.hostname,
          uri_image_no_comm: resp?.data?.uri_image_no_comm,
          manufacturer: resp?.data?.manufacturer,
          serial: resp?.data?.serial,
          model: resp?.data?.model,
          public_ip: resp?.data?.public_ip,
          local_ip: resp?.data?.local_ip,
        };
        setDataScanisette(scanisette);

        const antiVirus = resp.data.antivirus.map((av) => {
          return {
            id: av?.id,
            icon: av?.antivirus?.logo || av?.antivirus?.name,
            expires: av?.end_date,
            name: av.antivirus?.name,
            active: av?.activated,
            free: !av?.antivirus?.premium,
            version: av?.version || "16.1.14.0",
            date_check_version: av?.date_check_version || "2023-04-14T15:00:00",
          };
        });
        setDataAntiVirus(antiVirus);
        setLoading(false);
      })
      .catch((e) => console.error("Error while retrieving the scanisette", e));

    getLogByScanisetteId(scanisetteId)
      .then((resp) => {
        const logs = resp.data.map((log) => {
          return {
            id: log.id,
            date: log.date,
            message: log.message,
          };
        });
        setDataLog(
          logs.sort((a, b) => {
            return a.date > b.date ? -1 : 1;
          })
        );
      })
      .catch((e) =>
        console.error("Error while retrieving the logs by scanisette ID", e)
      );
  };

  useEffect(() => {
    getData();
    // setDataCom(communication);
    // setDataGame(games);

    getCustomerByScanisetteId(scanisetteId)
      .then((resp) => {
        setCustomerId(resp.data.id);
      })
      .catch((e) =>
        console.error("Error while retrieving the customer by scanisette Id", e)
      );
  }, [scanisetteId]);

  useEffect(() => {
    setScanissetteName(dataScanisette.name);
  }, [dataScanisette]);

  const uploadImageScan = (event) => {};

  const getPlaceGroups = () => {
    getAllPlaceGroupsByCustomerId(customerId)
      .then((resp) => {
        const sortByLocationOptions = resp.data.map((o) => {
          return {
            value: o.id,
            label: o.name,
          };
        });
        setLocationOptions(sortByLocationOptions);
      })
      .catch((e) =>
        console.error(
          "Error while retrieving the place groups by customer id",
          e
        )
      );
  };

  useEffect(() => {
    if (customerId) {
      getPlaceGroups();
    }
  }, [customerId]);

  const handleSaveScanisette = async () => {
    // We update the place group to change the place group for a specific scanisette
    if (imageScan) {
      let fd = new FormData();
      fd.append("file", imageScan);
      let resp = await uploadFile(fd);
      await updateScanisette(scanisetteId, {
        uri_image_no_comm: resp.data.name,
      });
      setImageScan(null);
      setPreviewImage(null);
      getData();
    }
    if (
      scanisetteLocationInput.trim() === "" &&
      scanisetteLocationSelect &&
      scanissetteName.trim() !== ""
    ) {
      updatePlaceGroupById(scanisetteLocationSelect, {
        scanisettes: [{ id: scanisetteId }],
      })
        .then((resp) => {
          updateScanisette(scanisetteId, {
            custom_name: scanissetteName,
          })
            .then((resp) => getData())
            .catch((err) =>
              console.error("Error while updating the scanisette name", err)
            );
        })
        .catch((e) =>
          console.error(
            "Error while updating the place group for the scanisette",
            e
          )
        );
    } else if (
      scanisetteLocationInput.trim() !== "" &&
      scanissetteName.trim() !== ""
    ) {
      createPlaceGroup({
        name: scanisetteLocationInput,
        note: "",
        customer: { id: customerId },
      })
        .then((resp) => {
          updatePlaceGroupById(resp.data.id, {
            scanisettes: [{ id: scanisetteId }],
          })
            .then((resp) => {
              updateScanisette(scanisetteId, {
                custom_name: scanissetteName,
              })
                .then((resp) => {
                  getData();
                  getPlaceGroups();
                })
                .catch((err) =>
                  console.error("Error while updating the scanisette name", err)
                );
            })
            .catch((e) =>
              console.error(
                "Error while updating the place group for the scanisette",
                e
              )
            );
        })
        .catch((e) => console.error("Error while creating the place group", e));
    } else if (
      scanisetteLocationInput.trim() === "" &&
      !scanisetteLocationSelect &&
      scanissetteName.trim() !== ""
    ) {
      updateScanisette(scanisetteId, { custom_name: scanissetteName })
        .then((resp) => getData())
        .catch((err) =>
          console.error("Error while updating the scanisette name", err)
        );
    } else if (
      scanisetteLocationInput.trim() === "" &&
      scanisetteLocationSelect &&
      scanissetteName.trim() === ""
    ) {
      updatePlaceGroupById(scanisetteLocationSelect, {
        scanisettes: [{ id: scanisetteId }],
      })
        .then((resp) => getData())
        .catch((e) =>
          console.error(
            "Error while updating the place group for the scanisette",
            e
          )
        );
    } else if (
      scanisetteLocationInput.trim() !== "" &&
      scanissetteName.trim() === ""
    ) {
      createPlaceGroup({
        name: scanisetteLocationInput,
        note: "",
        customer: { id: customerId },
      })
        .then((resp) => {
          updatePlaceGroupById(resp.data.id, {
            scanisettes: [{ id: scanisetteId }],
          })
            .then((resp) => {
              getData();
              getPlaceGroups();
            })
            .catch((e) =>
              console.error(
                "Error while updating the place group for the scanisette",
                e
              )
            );
        })
        .catch((e) => console.error("Error while creating the place group", e));
    }
    setScanisetteLocationInput("");
    setScanisetteLocationSelect("");
    setScanissetteName("");
    setManageModal(false);
  };

  const getLastPageScan = () => {
    if (scanisette) {
      return scanisette.scans.length > Math.floor(scanisette.scans.length / 10)
        ? Math.floor(scanisette.scans.length / 10) + 1
        : Math.floor(scanisette.scans.length / 10);
    } else {
      return -1;
    }
  };

  const currentDataLog = useMemo(() => {
    const firstPageIndex = (currentPageLog - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(dataLog.length / PageSize);
    if (totalPageCount < currentPageLog) {
      setCurrentPageLog(1);
    }
    return dataLog.slice(firstPageIndex, lastPageIndex);
  }, [dataLog, currentPageLog, PageSize]);

  useEffect(() => {
    if (scanisette) {
      let array = [];
      scanisette.scans.forEach((scan) => {
        const existingEntry = array.find(
          (entry) => entry.id === scan.scan_identifier
        );

        if (existingEntry) {
          existingEntry.scan.push(scan);
        } else {
          array.push({ id: scan.scan_identifier, scan: [scan] });
        }
      });
      setFormattedScans(array);
    }
  }, [scanisette]);

  useEffect(() => {
    const firstPageIndex = (currentPageScan - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const totalPageCount = Math.ceil(formattedScans.length / PageSize);
    if (totalPageCount < currentPageScan) {
      setCurrentPageScan(1);
    }
    setCurrentDataScan(
      formattedScans
        .sort((a, b) => {
          const dateA = new Date(a.scan[0].date);
          const dateB = new Date(b.scan[0].date);

          return dateB - dateA;
        })
        .slice(firstPageIndex, lastPageIndex)
    );
  }, [formattedScans, currentPageScan, PageSize]);

  function checkInactiveAntivirus(antivirusData) {
    let inactiveCount = 0;
    for (const antivirus of antivirusData) {
      if (!antivirus.active) {
        inactiveCount++;
      }
    }
    return inactiveCount;
  }

  const activateDesactivateAV = (e) => {
    const inactiveCount = checkInactiveAntivirus(dataAntiVirus);
    setLoading(true);
    if (
      dataAntiVirus.filter((AV) => AV.id === +e.target.id.split("-")[2])[0]
        .active &&
      inactiveCount < dataAntiVirus.length - 2
    ) {
      desactivateAVForScanisette(dataScanisette.id, +e.target.id.split("-")[2])
        .then(() => {
          getData();
        })
        .catch((e) =>
          console.error("Error while desactivating the antivirus", e)
        );
    } else if (
      dataAntiVirus.filter((AV) => AV.id === +e.target.id.split("-")[2])[0]
        .active &&
      inactiveCount === dataAntiVirus.length - 2
    ) {
      desactivateAVForScanisette(dataScanisette.id, +e.target.id.split("-")[2])
        .then(() => {
          getData();
          setOpenOnlySuspicionModal(true);
        })
        .catch((e) =>
          console.error("Error while desactivating the antivirus", e)
        );
    } else if (
      dataAntiVirus.filter((AV) => AV.id === +e.target.id.split("-")[2])[0]
        .active &&
      inactiveCount === dataAntiVirus.length - 1
    ) {
      toast.error("Il n'est pas possible de désactiver tous les antivirus");
      setLoading(false);
    } else {
      activateAVForScanisette(dataScanisette.id, +e.target.id.split("-")[2])
        .then(() => {
          getData();
        })
        .catch((e) => console.error("Error while activating the antivirus", e));
    }
  };

  const handleDeletePlaceGroup = (id) => {
    deletePlaceGroupById(id)
      .then(() => {
        getPlaceGroups();
      })
      .catch((e) =>
        console.error("Error while trying to delete a placegroup", e)
      );
  };

  const handleModifyPlaceGroup = (id, label) => {
    if (showInputEle) {
      setShowInputEle("");
    } else {
      setShowInputEle(id);
      setPlaceGroupLabel(label);
    }
  };

  const handleSavePlaceGroup = (id) => {
    updatePlaceGroupById(id, {
      name: placeGroupLabel,
    })
      .then(() => {
        getPlaceGroups();
        setShowInputEle("");
      })
      .catch((e) => console.error("Error while updating the place group", e));
  };

  return (
    <Layout>
      {!(dataScanisette && scanisette) ? (
        <div className="d-flex align-items-center justify-content-center w100 full-height">
          <SyncLoader size={50} margin={20} loading color="#0042eb" />
        </div>
      ) : (
        <>
          <h1 className="title">
            <img
              src="/assets/goback.svg"
              alt="Go back icon"
              className="go-back"
              onClick={() => navigate(-1)}
            />
            {dataScanisette.name}
          </h1>
          <div className="scanisette">
            <div className="d-flex flex-column align-items-center">
              <CardScanisette dataScanisette={dataScanisette} />

              <Button
                customClass={"basic_button btn-order"}
                label="Paramètres de la scanisette"
                type={"button"}
                onClick={() => setManageModal(true)}
              />
              <Button
                customClass={"basic_button btn-order mt-3"}
                label="Afficher les logs"
                type={"button"}
                onClick={() => setOpenLogModal(true)}
              />

              <TrueModal
                show={manageModal}
                onClose={() => {
                  setManageModal(false);
                  setScanissetteName(dataScanisette.name);
                }}
                classNameModalWrapper={
                  "true-modal position-fixed b-background-modal d-flex align-items-center justify-content-center z-1000"
                }
                classNameModalContent={"b-modal max-hvh90 wpx500 overflow-auto"}
              >
                <div className="p-3">
                  <div className="flex-end">
                    <img
                      src="/assets/annuler.svg"
                      alt="Icône fermeture"
                      onClick={() => {
                        setManageModal(false);
                        setScanissetteName(dataScanisette.name);
                      }}
                    />
                  </div>
                  <h3 className="modal-title">Paramètres de la scanisette</h3>
                </div>

                <div className="p-3">
                  <div>
                    <div>Modifier le nom de la scanisette :</div>
                    <Input
                      customClass={"input-scanisette-name"}
                      type="text"
                      value={scanissetteName}
                      onChange={(e) => setScanissetteName(e.target.value)}
                    />
                  </div>
                  <div className="d-flex column g-2 align-items-start">
                    <div className="mt-8">
                      <div>Choisir un lieu :</div>
                      <select
                        name="location"
                        className={`select-menu location`}
                        onChange={(e) =>
                          setScanisetteLocationSelect(e.target.value)
                        }
                      >
                        <option value="">--Choisir un lieu--</option>
                        {locationOptions.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div className="">Ou créer un lieu :</div>
                      <Input
                        customClass={"input-scanisette-location"}
                        type="text"
                        value={scanisetteLocationInput}
                        onChange={(e) =>
                          setScanisetteLocationInput(e.target.value)
                        }
                      />
                    </div>
                    <Button
                      customClass={"basic_button btn-order"}
                      label="Gérer les lieux"
                      type={"button"}
                      onClick={() => setOpenPlaceGroupModal(true)}
                    />
                  </div>
                  <div className="d-flex f-column g-1">
                    <div className="mt-8">Modifier l'image de scan :</div>
                    <Input
                      customClass={"input-scanisette-location"}
                      type="file"
                      onChange={(e) => setImageScan(e.target.files[0])}
                      accept={"image/*"}
                    />
                    {previewImage ? (
                      <img className="image_upload" src={previewImage}></img>
                    ) : dataScanisette.uri_image_no_comm ? (
                      <img
                        className="image_upload"
                        src={`${process.env.REACT_APP_API_URL}/${dataScanisette.uri_image_no_comm}`}
                      ></img>
                    ) : (
                      <img
                        className="image_upload"
                        src={`${process.env.REACT_APP_API_URL}/background_scan_scanisette.png`}
                      ></img>
                    )}
                  </div>
                </div>

                <div className="p-3">
                  <Button
                    customClass={"basic_button btn-order"}
                    label="Confirmer la modification"
                    type={"button"}
                    onClick={handleSaveScanisette}
                  />
                </div>
              </TrueModal>
              <TrueModal
                show={openLogModal}
                onClose={() => setOpenLogModal(false)}
                classNameModalWrapper={
                  "true-modal position-fixed b-background-modal d-flex align-items-center justify-content-center z-1000"
                }
                classNameModalContent={
                  "b-modal min-wpx500 max-hvh90 max-wvw90 overflow-auto"
                }
              >
                <div className="p-3">
                  <div className="flex-end">
                    <img
                      src="/assets/annuler.svg"
                      alt="Icône fermeture"
                      onClick={() => setOpenLogModal(false)}
                    />
                  </div>
                  <h3 className="modal-title">Logs de la scanisette</h3>
                </div>

                <div className="p-3">
                  <Table
                    theadData={tableHeaderLogs}
                    tbodyData={currentDataLog}
                    customClass={"table-scanisettes"}
                    TableRow={ScanisetteLogScanTableRow}
                  />
                  <Pagination
                    currentPage={currentPageLog}
                    totalCount={dataLog.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPageLog(page)}
                  />
                </div>
              </TrueModal>

              <TrueModal
                show={openPlaceGroupModal}
                onClose={() => {
                  setOpenPlaceGroupModal(false);
                }}
                classNameModalWrapper={
                  "true-modal position-fixed b-background-modal d-flex align-items-center justify-content-center z-1000"
                }
                classNameModalContent={"b-modal max-hvh90 wpx600 overflow-auto"}
              >
                <div className="p-3">
                  <div className="flex-end">
                    <img
                      src="/assets/annuler.svg"
                      alt="Icône fermeture"
                      onClick={() => {
                        setOpenPlaceGroupModal(false);
                      }}
                    />
                  </div>
                  <h3 className="modal-title">Gestion des lieux</h3>
                </div>
                <div className="p-3">
                  <div className="d-flex column align-items-start g-2">
                    <div>Lieux à modifier ou supprimer :</div>
                    <div className="d-flex flex-wrap g-2 w100 justify-content-start">
                      {locationOptions.map((o, idx) => (
                        <>
                          <div
                            className={`w44 d-flex justify-content-space-between align-items-center`}
                            
                          >
                            <ElementMaker
                              customClass={"w80"}
                              type={"text"}
                              value={
                                showInputEle === o.value
                                  ? placeGroupLabel
                                  : o.label
                              }
                              handleChange={(e) =>
                                setPlaceGroupLabel(e.target.value)
                              }
                              showInputEle={showInputEle === o.value}
                              id="placeGroupName"
                              customClassSpan={"w80"}
                            />
                            <div className="d-flex g-2">
                              {showInputEle === o.value ? (
                                <img
                                  src="/assets/tick.svg"
                                  alt="Tick icon"
                                  width={"15"}
                                  height={"15"}
                                  onClick={() => handleSavePlaceGroup(o.value)}
                                />
                              ) : null}
                              {showInputEle === o.value ? (
                                <img
                                  src="/assets/annuler.svg"
                                  alt="Modify icon"
                                  width={"12"}
                                  height={"12"}
                                  className="pointer"
                                  onClick={() =>
                                    handleModifyPlaceGroup(o.value, o.label)
                                  }
                                />
                              ) : (
                                <img
                                  src="/assets/pen.svg"
                                  alt="Modify icon"
                                  width={"15"}
                                  height={"15"}
                                  className="pointer"
                                  onClick={() =>
                                    handleModifyPlaceGroup(o.value, o.label)
                                  }
                                />
                              )}
                              <img
                                src="/assets/trash.svg"
                                alt="Trash icon"
                                width={"15"}
                                height={"15"}
                                className="pointer"
                                onClick={() => handleDeletePlaceGroup(o.value)}
                              />
                            </div>
                          </div>
                          {idx % 2 === 0 && idx < locationOptions.length - 1 ? (
                            <div key={idx} className="vertical-separator ml-2 mr-2"></div>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <Button
                    customClass={"basic_button btn-order"}
                    label="Fermer la modale"
                    type={"button"}
                    onClick={() => {
                      setOpenPlaceGroupModal(false);
                    }}
                  />
                </div>
              </TrueModal>

              <TrueModal
                show={openOnlySuspicionModal}
                onClose={() => {
                  setOpenOnlySuspicionModal(false);
                }}
                classNameModalWrapper={
                  "true-modal position-fixed b-background-modal d-flex align-items-center justify-content-center z-1000"
                }
                classNameModalContent={"b-modal max-hvh90 wpx500 overflow-auto"}
              >
                <div className="p-3">
                  <div className="flex-end">
                    <img
                      src="/assets/annuler.svg"
                      alt="Icône fermeture"
                      onClick={() => {
                        setOpenOnlySuspicionModal(false);
                      }}
                    />
                  </div>
                  <h3 className="modal-title">Suspicion de virus</h3>
                </div>

                <div className="p-3">
                  ATTENTION ! Il n'y a plus qu'un seul antivirus activé. Vous
                  n'aurez que des suspicions de virus.
                </div>

                <div className="p-3">
                  <Button
                    customClass={"basic_button btn-order"}
                    label="J'ai compris"
                    type={"button"}
                    onClick={() => {
                      setOpenOnlySuspicionModal(false);
                    }}
                  />
                </div>
              </TrueModal>
            </div>

            {role === "Admin" ? (
              <div className="w100">
                <h3>Antivirus</h3>
                <div className="d-flex flex-column g-3">
                  {dataAntiVirus.map((av) => (
                    <TrueCard
                      key={v4()}
                      classNameCardWrapper={
                        "w98 hpx120 grid grid-cols-4 align-items-center g-3 p-3 large-radius border-lg b-border-white-blue box-shadow-hover box-border"
                      }
                    >
                      <div className="justify-self-center">
                        <img
                          src={av.icon}
                          alt="Icon antivirus"
                          className="object-contain hpx80 wpx80"
                        />
                      </div>

                      <div className="">
                        <div
                          className="d-flex align-items-center g-2"
                          title={
                            licenceExpired(av.date_check_version) > 24
                              ? "La base virale a plus de 24h"
                              : licenceExpired(av.date_check_version) < 6
                              ? "La base virale a moins de 6h"
                              : "La base virale a plus de 6h et moins de 24h"
                          }
                        >
                          <img
                            src={
                              licenceExpired(av.date_check_version) > 24
                                ? "/assets/check_inactive.svg"
                                : licenceExpired(av.date_check_version) < 6
                                ? "/assets/check_active.svg"
                                : "/assets/check_almost-inactive.svg"
                            }
                            alt="Check"
                          />
                          <span className="bold">
                            {licenceExpired(av.date_check_version) > 24
                              ? "Expirée"
                              : licenceExpired(av.date_check_version) < 6
                              ? "A jour"
                              : "Expire bientôt"}
                          </span>
                        </div>

                        <div className="mt-2 font-size-sm">
                          Version base virale:{" "}
                          {formatDate(
                            new Date(av.date_check_version),
                            "yyyy-mm-dd HH:MM:SS"
                          )}
                        </div>
                      </div>

                      <div className="d-flex align-items-center g-2">
                        {loading ? (
                          <SyncLoader
                            size={5}
                            margin={5}
                            loading
                            color="#0042eb"
                          />
                        ) : (
                          <Switch
                            isOn={av.active}
                            onColor="#5f8bfa"
                            id={`${av.name}-${av.id}`}
                            handleToggle={activateDesactivateAV}
                          />
                        )}
                        {av.active ? "Antivirus activé" : "Antivirus désactivé"}
                      </div>
                      <div className="bold blue justify-self-center">
                        {av.free ? "Gratuit" : null}
                      </div>
                    </TrueCard>
                  ))}
                </div>
                {/* <hr />
            <h3>Communication</h3>
            {dataCom.map((c) => (
              <FlatCardCom
                key={v4()}
                data={c}
                active={c.active}
                customClass="grid-4-custom"
              />
            ))}
            <hr />
            <h3>Jeux</h3>
            {dataGame.map((g) => (
              <FlatCardGame
                key={v4()}
                data={g}
                active={g.active}
                customClass="grid-4-custom"
              />
            ))} */}
                <hr className="w96" />
                <h3>Date anniversaire</h3>
                <div className="mb-6">
                  {dataScanisette?.anniversary?.split("T")[0]}
                </div>
                <hr className="w96" />
                <div className="d-flex flex-column">
                  <div className="w98 p-3 large-radius border-lg b-border-white-blue box-border">
                    <div className="d-flex justify-between">
                      <div className="d-flex column">
                        <h3>IP Publique</h3>
                        <div className="">{dataScanisette?.public_ip}</div>
                      </div>
                      <div className="d-flex column ml-12">
                        <h3 className="text-end">IP Locale</h3>
                        <div className="text-end">
                          {dataScanisette?.local_ip}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-between mt-6">
                      <div className="d-flex column">
                        <h3>Modèle</h3>
                        <div className="">
                          {dataScanisette?.model ? dataScanisette.model : "N/A"}
                        </div>
                      </div>
                      <div className="d-flex column ml-12">
                        <h3 className="text-end">Manufacturer</h3>
                        <div className="text-end">
                          {dataScanisette?.manufacturer
                            ? dataScanisette.manufacturer
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between mt-6">
                      <div className="d-flex column">
                        <h3>Serial</h3>
                        <div className="">
                          {dataScanisette?.serial
                            ? dataScanisette.serial
                            : "N/A"}
                        </div>
                      </div>
                      <div className="d-flex column ml-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w100">
                <h3>Antivirus</h3>
                <div className="d-flex flex-column g-3">
                  {dataAntiVirus.map((av) => (
                    <TrueCard
                      key={v4()}
                      classNameCardWrapper={
                        "w98 hpx120 grid grid-cols-4 align-items-center g-3 p-3 large-radius border-lg b-border-white-blue box-shadow-hover box-border"
                      }
                    >
                      <div className="justify-self-center">
                        <img
                          src={av.icon}
                          alt="Icon antivirus"
                          className="object-contain hpx80 wpx80"
                        />
                      </div>

                      <div className="">
                        <div
                          className="d-flex align-items-center g-2"
                          title={
                            licenceExpired(av.date_check_version) > 24
                              ? "La base virale a plus de 24h"
                              : licenceExpired(av.date_check_version) < 6
                              ? "La base virale a moins de 6h"
                              : "La base virale a plus de 6h et moins de 24h"
                          }
                        >
                          <img
                            src={
                              licenceExpired(av.date_check_version) > 24
                                ? "/assets/check_inactive.svg"
                                : licenceExpired(av.date_check_version) < 6
                                ? "/assets/check_active.svg"
                                : "/assets/check_almost-inactive.svg"
                            }
                            alt="Check"
                          />
                          <span className="bold">
                            {licenceExpired(av.date_check_version) > 24
                              ? "Expirée"
                              : licenceExpired(av.date_check_version) < 6
                              ? "A jour"
                              : "Expire bientôt"}
                          </span>
                        </div>

                        <div className="mt-2 font-size-sm">
                          Version base virale:{" "}
                          {formatDate(
                            new Date(av.date_check_version),
                            "yyyy-mm-dd HH:MM:SS"
                          )}
                        </div>
                      </div>

                      <div className="d-flex align-items-center g-2">
                        <img
                          src={
                            av.active
                              ? "/assets/check_active.svg"
                              : "/assets/check_inactive.svg"
                          }
                          alt="Check"
                        />
                        <span className="bold">
                          {av.active
                            ? "Antivirus activé"
                            : "Antivirus désactivé"}
                        </span>
                      </div>
                      <div className="bold blue justify-self-center">
                        {av.free ? "Gratuit" : null}
                      </div>
                    </TrueCard>
                  ))}
                </div>

                {/* <hr />
            <h3>Communication</h3>
            {dataCom.map((c) => (
              <FlatCardCom2
                key={v4()}
                data={c}
                active={c.active}
                customClass="grid-4-custom"
              />
            ))}
            <hr />
            <h3>Jeux</h3>
            {dataGame.map((g) => (
              <FlatCardGame3
                key={v4()}
                data={g}
                active={g.active}
                customClass="grid-4-custom"
              />
            ))} */}
                <hr className="w96" />
                <h3>Date anniversaire</h3>
                <div className="mb-6">
                  {dataScanisette?.anniversary?.split("T")[0]}
                </div>
                <hr className="w96" />
                <div className="d-flex flex-column">
                  <div className="w98 p-3 large-radius border-lg b-border-white-blue box-border">
                    <div className="d-flex justify-between">
                      <div className="d-flex column">
                        <h3>IP Publique</h3>
                        <div className="">{dataScanisette?.public_ip}</div>
                      </div>
                      <div className="d-flex column ml-12">
                        <h3 className="text-end">IP Locale</h3>
                        <div className="ext-end">
                          {dataScanisette?.local_ip}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-between mt-6">
                      <div className="d-flex column">
                        <h3>Modèle</h3>
                        <div className="">
                          {dataScanisette?.model ? dataScanisette.model : "N/A"}
                        </div>
                      </div>
                      <div className="d-flex column ml-12">
                        <h3 className="text-end">Manufacturer</h3>
                        <div className="">
                          {dataScanisette?.manufacturer
                            ? dataScanisette.manufacturer
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-between mt-6">
                      <div className="d-flex column">
                        <h3>Serial</h3>
                        <div className="">
                          {dataScanisette?.serial
                            ? dataScanisette.serial
                            : "N/A"}
                        </div>
                      </div>
                      <div className="d-flex column ml-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-column mb-5">
            <div className="flex flex-column list_virus mt-10 ">
              {currentDataScan ? (
                currentDataScan.map((s, idx) => (
                  <details key={s.id} className="scan_details">
                    <summary className="scan_row grid grid-cols-4 align-items-center">
                      <span className="ml-10 d-flex align-items-center g-1">
                        <span className="tag_scan">DATE</span>{" "}
                        {formatDate(
                          new Date(s.scan[0].date),
                          "yyyy-mm-dd HH:MM:SS"
                        )}
                      </span>
                      <span>
                        <span className="tag_scan">ANTIVIRUS</span>{" "}
                        {s.scan.length}
                      </span>
                      <span>
                        <span className="tag_scan">FICHIERS</span>{" "}
                        {calculateAverageFileCount(s.scan).toFixed(0)}
                      </span>
                      <span className="mr-10">
                        <span
                          className={`tag_scan ${
                            checkForMultipleAntivirusDetection(s.scan) >= 2
                              ? "b_danger"
                              : checkForMultipleAntivirusDetection(s.scan) === 1
                              ? "b_warning"
                              : "b_ok"
                          }`}
                        >
                          {checkForMultipleAntivirusDetection(s.scan) >= 2
                            ? "VIRUS"
                            : checkForMultipleAntivirusDetection(s.scan) === 1
                            ? "SUSPICION"
                            : "VIRUS"}
                        </span>{" "}
                        {Math.ceil(calculateAverageVirusCount(s.scan))}
                      </span>
                    </summary>
                    <div className="detail_scan">
                      {s.scan.map((sc, idx) => (
                        <details key={idx} className="scan_details ml-10">
                          <summary className="scan_row grid grid-cols-3 align-items-center">
                            <span className="ml-10 d-flex align-items-center g-1">
                              <span className="tag_scan">ANTIVIRUS</span>{" "}
                              {sc.antivirus}
                            </span>
                            <span>
                              <span className="tag_scan">FICHIERS</span>{" "}
                              {sc.nb_files}
                            </span>
                            <span className="mr-10">
                              <span
                                className={`tag_scan ${
                                  sc.nb_virus === 0
                                    ? "b_ok"
                                    : checkForMultipleAntivirusDetection(
                                        s.scan
                                      ) >= 2
                                    ? "b_danger"
                                    : checkForMultipleAntivirusDetection(
                                        s.scan
                                      ) === 1
                                    ? "b_warning"
                                    : "b_ok"
                                }`}
                              >
                                {sc.nb_virus === 0
                                  ? "VIRUS"
                                  : checkForMultipleAntivirusDetection(
                                      s.scan
                                    ) >= 2
                                  ? "VIRUS"
                                  : checkForMultipleAntivirusDetection(
                                      s.scan
                                    ) === 1
                                  ? "SUSPICION"
                                  : "VIRUS"}
                              </span>{" "}
                              {sc.nb_virus}
                            </span>
                          </summary>
                          <div className="detail_scan">
                            {sc.virus.length > 0 ? (
                              sc.virus.map((v, index) => (
                                <div key={index} className="virus_detail">
                                  <span className="ml-10">
                                    <span className="tag_scan">VIRUS</span>{" "}
                                    {v.name}
                                  </span>
                                  <span className="mr-10">
                                    <span className="tag_scan">FICHIER</span>{" "}
                                    {v.file}
                                  </span>
                                </div>
                              ))
                            ) : (
                              <p className="no_virus">Aucun virus détecté</p>
                            )}
                          </div>
                        </details>
                      ))}
                    </div>
                  </details>
                ))
              ) : (
                <></>
              )}
            </div>
            <Pagination
              currentPage={currentPageScan}
              totalCount={formattedScans.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPageScan(page)}
            />
          </div>{" "}
        </>
      )}
    </Layout>
  );
}

export default Scanisette;
