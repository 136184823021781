import React, { useState } from "react";
import { useEffect } from "react";
import ElementMaker from "../../components/ElementMaker.generic";
import { updateCustomerById } from "../../services/Customer.services";
import { updateUserById } from "../../services/User.services";
import { getAllRoles } from "../../services/Role.services";

function UserTableRow({ data, selectedRow, setSelectedRow, getDatas }) {
  const [showInputEle, setShowInputEle] = useState(false);
  const [dataUser, setDataUser] = useState(data);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setDataUser(data);
    getAllRoles()
      .then((resp) => {
        let rolesArr = 
          resp.data.map((r) => {
            return {
              value: r.id,
              label: r.name,
            };
          });
        setRoles(rolesArr);
      })
      .catch((e) => console.error("Error while retrieving the roles", e));
  }, [data]);

  const handleCheckboxChange = (e) => {
    // If the selected row are not yet in the array add it, else remove it from the array
    let newArray = [...selectedRow, e.target.value];
    if (selectedRow.includes(e.target.value)) {
      newArray = newArray.filter((d) => d !== e.target.value);
    }
    setSelectedRow(newArray);
  };

  const handleModifyUser = () => {
    updateCustomerById(dataUser.customer_id, {
      name: dataUser.customer_name,
    })
      .then(() => {
        updateUserById(dataUser.id, {
          email: dataUser.email,
          firstname: dataUser.firstname,
          name: dataUser.lastname,
          role: { id: +dataUser.role_id },
          // TODO: modify the role
        })
          .then(() => {
            getDatas();
            setShowInputEle(false);
          })
          .catch((e) => console.error("Error while updating the user", e));
      })
      .catch((e) => console.error("Error while updating the customer", e));
  };

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          id={dataUser.id}
          value={dataUser.id}
          onChange={handleCheckboxChange}
          className="pointer"
        />
      </td>
      <td>
        <ElementMaker
          customClass={"input-user"}
          type={"text"}
          value={dataUser.customer_name}
          handleChange={(e) =>
            setDataUser({ ...dataUser, customer_name: e.target.value })
          }
          showInputEle={showInputEle}
        />
      </td>
      <td>
        <ElementMaker
          customClass={"input-user"}
          type={"text"}
          value={dataUser.lastname}
          handleChange={(e) =>
            setDataUser({ ...dataUser, lastname: e.target.value })
          }
          showInputEle={showInputEle}
        />
      </td>
      <td>
        <ElementMaker
          customClass={"input-user"}
          type={"text"}
          value={dataUser.firstname}
          handleChange={(e) =>
            setDataUser({ ...dataUser, firstname: e.target.value })
          }
          showInputEle={showInputEle}
        />
      </td>
      <td>
        <ElementMaker
          customClass={"input-user"}
          type={"text"}
          value={dataUser.email}
          handleChange={(e) =>
            setDataUser({ ...dataUser, email: e.target.value })
          }
          showInputEle={showInputEle}
        />
      </td>
      <td>
        {showInputEle ? (
          <select
            className="select-user"
            onChange={(e) =>
              setDataUser({ ...dataUser, role_id: +e.target.value })
            }
            value={dataUser.role_id}
          >
            {roles.map(role => <option key={role.value} value={role.value}>{role.label}</option>)}
          </select>
        ) : (
          <span>{dataUser.role}</span>
        )}
      </td>
      <td>
        {showInputEle ? (
          <>
            <img
              src="/assets/tick.svg"
              alt="Icône valider"
              onClick={() => handleModifyUser()}
              className="pointer"
            />{" "}
            <img
              src="/assets/annuler.svg"
              alt="Icône annuler"
              onClick={() => setShowInputEle(false)}
              className="pointer"
            />
          </>
        ) : (
          <img
            src="/assets/arrow.svg"
            alt="Icône flèche droite"
            onClick={() => setShowInputEle(true)}
            className="pointer"
          />
        )}
      </td>
    </tr>
  );
}

export default UserTableRow;
