import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { v4 } from "uuid";
import Layout from "../layouts/Layout";
import { getTicketById, addReply } from "../services/Ticket.services";
import { formatDate } from "../services/Utils.services";

function Ticket() {
  const location = useLocation();
  const customerId = location.state.customer;
  const { ticketId } = useParams();
  const [dataTicket, setDataTicket] = useState([]);
  const [dataMessage, setDataMessage] = useState([]);
  const [typedInput, setTypedInput] = useState("");

  useEffect(() => {
    // With the ticket id get the data
    getTicketById(ticketId).then((resp) => {
      const data = resp.data;
      const ticket = {
        id: data?.id,
        status: data?.status,
        content: data?.content,
        name: data?.title,
        contact: data?.creatorEmail,
        date: data?.createOn,
        update: data?.updateOn,
        messages: data?.replyes,
      };
      setDataTicket(ticket);
      const messages = ticket.messages.map((message) => {
        return {
          sender: message?.creatorName,
          text: extractTextContent(extractTextContent(message?.content)), //We have to apply the function 2 times to first tranform the &#60; into < and then to transform the html into plain text
          date: new Date(message.createOn),
          weight: message?.creatorWeight,
        };
      });
      setDataMessage(messages);
    });
  }, [ticketId]);

  const sendMessage = (e) => {
    e.preventDefault();
    if (e?.target?.response?.value?.trim() !== "") {
      addReply({
        id_ticket: parseInt(ticketId),
        content: e?.target?.response?.value?.trim(),
      }).then((resp) => {
        const data = resp.data;
        const ticket = {
          id: data?.id,
          status: data?.status,
          content: data?.content,
          name: data?.title,
          contact: data?.creatorEmail,
          date: data?.createOn,
          update: data?.updateOn,
          messages: data?.replyes,
        };
        setDataTicket(ticket);
        const messages = ticket.messages.map((message) => {
          return {
            sender: message?.creatorName,
            text: extractTextContent(extractTextContent(message?.content)),
            date: new Date(message.createOn),
            weight: message?.creatorWeight,
          };
        });
        setDataMessage(messages);
      });
    }
    setTypedInput("");
  };

  const extractTextContent = (textFromAPI) => {
    const divContainer= document.createElement("div");
    divContainer.innerHTML = textFromAPI;
    return divContainer.textContent || divContainer.innerText || "";
  }

  return (
    <Layout>
      <h1 className="title d-flex align-items-center">
        <Link to="/tickets" state={{ customer: customerId }}>
          <img
            src="/assets/goback.svg"
            alt="Go back icon"
            className="go-back"
          />
        </Link>
        <div className="tooltip">{dataTicket.name}</div>
      </h1>
      <div className="max-width">
        <div className="ticket-number border-md b-border-white-blue small-radius p-10">{dataTicket.content}</div>
        <div className="chat-window">
          {dataMessage.map((m) => (
            <div className="message-container" key={v4()}>
              <div className={m.weight <= 100 ? "left" : "right"}>
                <img src="/assets/profil-small.svg" alt="Small avatar" />
                <span className="bold left-margin">{m.weight <= 100 ? "Human's Connexion" : m.sender} - </span>
                <span className="blue">{formatDate(new Date(m.date), "yyyy-mm-dd")}</span>
              </div>
              <p className={m.weight <= 100 ? "hc-user" : "customer-user"}>
                {m.text}
              </p>
            </div>
          ))}
        </div>

        <form onSubmit={(e) => sendMessage(e)} className="chat-composer">
          <input
            type="text"
            id="response"
            placeholder="Répondre..."
            className="respond-input"
            value={typedInput}
            onChange={(e) => setTypedInput(e.target.value)}
          />
          <button type="submit" className="basic_button btn-order btn-right mb-3">
            Répondre
          </button>
        </form>
      </div>
    </Layout>
  );
}

export default Ticket;
