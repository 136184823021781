import React, { useContext, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import Button from "./Button.generic";
import Badge from "@mui/material/Badge";
import { AlertContext } from "../contexts/AlertContext";
import { useEffect } from "react";

function Header() {
  const { logout, user } = useContext(AuthContext);
  const {
    numberAlertsOutdated,
    numberAlertsInactive,
    dataInactive,
    dataOutdated,
  } = useContext(AlertContext);
  const navigate = useNavigate();
  const [accountLogout, setAccountLogout] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [role, setRole] = useState("");
  const [customers, setCustomer] = useState("");
  const menuRef = useRef(null);
  const [alertMenuIsOpen, setAlertMenuIsOpen] = useState(false);
  const alertRef = useRef(null);

  // const { firstname, role, customers } = user || "";

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    if (user) {
      setFirstname(user.firstname);
      setRole(user.role);
      setCustomer(user.customers);
    }
  }, [user]);

  const handleClickAvatar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAccountLogout(!accountLogout);
  };

  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setAccountLogout(false);
    }
  };

  useEffect(() => {
    if (accountLogout) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [accountLogout]);

  const handleClickAlert = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAlertMenuIsOpen(!alertMenuIsOpen);
  };

  const handleOutsideAlertClick = (e) => {
    if (alertRef.current && !alertRef.current.contains(e.target)) {
      setAlertMenuIsOpen(false);
    }
  };

  useEffect(() => {
    if (alertMenuIsOpen) {
      document.addEventListener("click", handleOutsideAlertClick);
    } else {
      document.removeEventListener("click", handleOutsideAlertClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideAlertClick);
    };
  }, [alertMenuIsOpen]);

  return (
    <header className="d-flex align-items-center justify-content-space-between ml-5 mr-5 w100 hpx120">
      <Link to="/dashboard">
        <img
          src={"/assets/logo.svg"}
          alt="Logo scanisette"
          className="object-contain hpx80"
        />
      </Link>
      {role.name === "Admin" ? (
        <div className="d-flex align-items-center g-2">
          <span className="bold fs24">{customers[0]?.name}</span>
          {customers[0].logo ? (
            <img
              src={customers[0]?.logo}
              alt="Logo customer"
              className="object-contain hpx120 wpx120"
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex align-items-center g-2">
        <span>
          Bonjour <span className="bold">{firstname}</span>
        </span>
        <img
          src={"/assets/profil.svg"}
          alt="Avatar"
          onClick={handleClickAvatar}
          className="pointer hpx60"
        />
        {role.name === "Admin" ? (
          <div onClick={handleClickAlert}>
            <Badge
              badgeContent={numberAlertsOutdated + numberAlertsInactive}
              color="error"
            >
              <img
                src={"/assets/alert.svg"}
                alt="Alert"
                className="pointer hpx40"
              />
            </Badge>
          </div>
        ) : (
          <></>
        )}
      </div>

      {accountLogout && (
        <div className="accountLogout" ref={menuRef}>
          <Button
            customClass={"basic_button btn-order"}
            label="Se déconnecter"
            type={"button"}
            onClick={() => handleLogout()}
          ></Button>
        </div>
      )}

      {alertMenuIsOpen && (
        <div
          className="alert-menu overflow-auto d-flex column g-2"
          ref={alertRef}
        >
          {dataInactive
            .filter((d) => d.isRead === false)
            .slice(0, 4)
            .map((d) => (
              <div
                key={d.id}
                className="max-wpx300 pointer primary primary-hover"
                onClick={() => navigate("/alerts?tab=tab1")}
              >
                {d.message}
              </div>
            ))}
          {dataOutdated
            .filter((d) => d.isRead === false)
            .slice(0, 4)
            .map((d) => (
              <div
                key={d.id}
                className="max-wpx300 pointer primary primary-hover"
                onClick={() => navigate("/alerts?tab=tab2")}
              >
                {d.message}
              </div>
            ))}
            <Button customClass={"basic_button btn-order"}
             label="Accéder à toutes les alertes"
             type={"button"}
             onClick={() => navigate("/alerts")}
          ></Button>
        </div>
      )}
    </header>
  );
}

export default Header;
