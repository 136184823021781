import React from "react";

import "./modal.style.scss";

function TrueModal({ children, show, onClose, classNameModalWrapper, classNameModalContent }) {
  if (!show) {
    return null;
  }

  return (
    <div className={classNameModalWrapper} onClick={onClose}>
      <div className={classNameModalContent} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}

export default TrueModal;
